import { Injectable, OnDestroy, Inject } from '@angular/core';
import * as _ from 'underscore';
import { PushMessage } from '../_models/notifications/PushMessage';
import { AuthService } from '../services/auth.service';
import { NotificationHubService, Channels } from '../services/notification-hub.service';
import { LoggingService } from '../services/logging.service';
import { SupportMessage } from '../_models/support/SupportMessage';
import { EventHubService } from '../services/event-hub.service';
import { SilentMessage } from '../_models/support/SilentMessage';
import { PlatformInfoProvider } from '../_dependencies/platform-info-provider';
@Injectable({
  providedIn: 'root'
})
export class SupportService implements OnDestroy
{
  public messages: PushMessage[] = [];
  private subs: any[] = [];
  constructor(private authService: AuthService,
    private notificationService: NotificationHubService,
    private logger: LoggingService,
    private eventHubService: EventHubService,
    @Inject('PlatformInfoProvider') private platformInfoProvider: PlatformInfoProvider)
  {
    this.subs.push(notificationService.messageUpdate.subscribe(() => this.updateMessages()));
    this.subs.push(notificationService.supportMessagePushed.subscribe(msg =>
      {
        if (!_.any(this.messages, m => m.id === msg.id))
        {
          this.messages.push(msg);
        }
      }));
    this.subs.push(notificationService.supportInfoRequestPushed.subscribe(() => this.silentMessageToSupport({error: null, message: null})));
    this.subs.push(eventHubService.silentSupportMessage.subscribe((sm: SilentMessage) => this.silentMessageToSupport(sm)));
    this.updateMessages();
  }

  ngOnDestroy(): void
  {
    this.subs.forEach(s => s.unsubscribe());
  }

  private updateMessages()
  {
    const currentMessages = this.notificationService.allMessages.filter(msg =>
      msg.channel === Channels.SupportMessageChannel ||
      msg.channel === Channels.SlackSupportChannel);
    if (this.messages.length !== currentMessages.length)
    {
      this.messages = currentMessages;
    }
  }

  async supportMessage(message: string): Promise<void>
  {
    await this.messageToSupport(message);
  }

  private async messageToSupport(message: string): Promise<void>
  {
    const id = this.authService.getCurrentIdentity();
    let companyId: string;
    if (id && id.company)
    {
      companyId = id.company.id;
    }
    const supportMessage: SupportMessage =
    {
      appInfo: undefined,
      frontEndInfo: await this.platformInfoProvider.getFrontendInfo(),
      message: message,
      companyId: companyId
    };
    await this.authService.authPost<SupportMessage>('/api/support/messagetosupport', supportMessage);
  }

  async silentMessageToSupport(m: SilentMessage): Promise<void>
  {
    let error: string = '';
    // the error can have
    // - a message property
    // - an error property
    error = m.error ? m.error.message : '';
    if (m.error && (<any>m.error).error)
    {
      error = error + ' | ' + (<any>m.error).error;
    }
    m.error = error;
    const id = this.authService.getCurrentIdentity();
    if (id && id.company)
    {
      m.companyId = id.company.id;
    }
    m.frontendInfo = await this.platformInfoProvider.getFrontendInfo();
    if (!m.error && !m.message && !m.appInfo)
    {
      return;
    }
    try
    {
      await this.authService.authPost('/api/support/silentmessage', m);
    }
    catch (err)
    {
      this.logger.logException(err);
    }
  }
}
