<div class="current-document">

  <mat-progress-spinner *ngIf="status === 'loading'" diameter="24" class="spinner" color="accent" mode="indeterminate"></mat-progress-spinner>

  <!-- PDF Document -->
  <div class="pdf-document" *ngIf="showPDFDocumentHelp()">
    <span class="description" translate>
      lid.ext.pages.work.current-document.docIsPdf
    </span>
    <button class="md-button md-button-highlighted" 
      (click)="savePDFasAi()" translate>
      lid.ext.pages.work.current-document.saveAsAi
    </button>
  </div>

  <!-- AI Document -->
  <ng-container *ngIf="!showPDFDocumentHelp()">
    <mat-tab-group 
      class="tab-group"
      [ngClass]="{'hidden': !showContent() || status === 'loading'}"
      (selectedTabChange)="onContentsClick($event)"
      animationDuration="0ms"
      [(selectedIndex)]="selectedTabIndex">
      <mat-tab label="Classification" *ngIf="mode === 'Import'">
        <div class="tab-content">
          <!-- tab classifiers -->
          <class-selector #classSelectorComponent
            (filterChanged)="filtersChanged($event)"
            [showAutoAssignButton]="showAutoAssignButton"
            (autoAssignClicked)="autoAssignClicked()"
            (doContentClassification)="doContentClassification($event)"
            [showAutoClassification]="true"
            [showResetButton]="showResetButton"
            [hideHardResetButton]="hideHardResetButton"
            [showCRUD]="true"
            [showAllText]="false"
            [multiClassSelection]="true"
            [multiDocTypeSelection]="false"
            [autoSelect]="false"
            [hideSearch]="true"
            [controlRequiredValues]="true"
            [preselection]="documentFilter"
            [disabled]="busy">
          </class-selector>
        </div>
      </mat-tab>
      <mat-tab label="Fields" *ngIf="currentDocument && currentDocument.documentType && 
                                     currentDocument.dataIllustratorMaps && currentDocument.dataIllustratorMaps.length > 0">
        <div class="tab-content">
          <field-list
            [data]="currentDocument.dataIllustratorMaps"
            [filepath]="currentDocument.workingCopy"
            (refresh)="updateContent()"
            (mapLinked)="linkFieldMap($event)">
          </field-list>
        </div>
      </mat-tab>
      <mat-tab label="Content" *ngIf="currentDocument?.documentType">
        <div class="tab-content" *ngIf="!loadingRules">
          <!-- tab document content -->
          <app-document-contents
            [contentGroups]="contentGroups"
            [workspaceRules]="rulesConfiguration"
            [currentDocument]="currentDocument">
          </app-document-contents>
        </div>
        <div class="busy-content" *ngIf="loadingRules">
          <mat-progress-spinner diameter="24" class="spinner" color="accent" mode="indeterminate"></mat-progress-spinner>
        </div>
      </mat-tab>
      <mat-tab label="Find & Replace">
        <div class="tab-content findreplace">
          <!-- tab find & replace -->
          <!-- FIND AND REPLACE -->
          <find-replace 
            [(findAndReplaceValues)]="findAndReplaceValues" 
            (findReplaceClicked)="findReplace()"
            (viewFindValueClicked)="selectValues($event, true)"
            (viewReplaceValueClicked)="selectValues($event, false)"
            [showViewIcons]="true"
            [showResetAll]="true">
          </find-replace>
          <div class="findreplace-actions">
            <button class="md-button"
              [disabled]="findReplaceDisabled()" 
              (click)="findReplace()"
              id="findAndReplace-button"
              >
              Find & Replace
            </button>
            <mat-checkbox class="check" [(ngModel)]="caseSensitive">Case sensitive</mat-checkbox>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="nutritionaTableTabLabel" *ngIf="hasNutritionalTablesModule && nutritionTable">
        <div class="tab-content">
          <app-nutritional-table [nutritionalTable]="nutritionTable" [currentDocumentFilePath]="currentDocument?.workingCopy"></app-nutritional-table>
        </div>
      </mat-tab>
      <mat-tab label="AI Analysis" *ngIf="mode === 'Edit' && hasIAModule() && 
                                          currentDocument && currentDocument.latestVersion && 
                                          currentDocument.latestVersion.aiAnalyses && currentDocument.latestVersion.aiAnalyses.length > 0">
        <div class="tab-content aiAnalysis">
          <app-document-ai-analysis [documentVersion]="currentDocument?.latestVersion"></app-document-ai-analysis>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>

</div>
