<div class="nutritional-table">
    <div class="nutritional-table-header">
        <span class="title">{{nutritionaTableTitle}}</span>
    </div>
    <div class="nutritional-table-body">
        <div class="table-container" *ngIf="nutritionalTableDocument || downloadingDocument">
            <span class="title" translate>lid.ext.pages._shared.nutritional-table.tableModel</span>
            <div class="document">
                <div class="actions">
                    <span class="action" 
                        *ngIf="!downloadingDocument"
                        (click)="pasteNutritionalTableDocument()"
                        [matTooltip]="'lid.ext.pages._shared.nutritional-table.pasteContent' | translate">
                        <mat-icon>content_paste_outline</mat-icon>
                    </span>
                    <span class="action" *ngIf="downloadingDocument">
                        <mat-progress-spinner diameter="18" class="spinner" color="accent" mode="indeterminate"></mat-progress-spinner>
                    </span>
                </div>
                <div class="document-image" *ngIf="nutritionalTableDocument && !loadingDocument">
                    <div class="image-container">
                        <img [src]="nutritionalTableDocument.previewImageUrl">
                    </div>
                    <span class="description">{{nutritionalTableDocument.moonNumber}}</span>
                </div>
                <div class="document-image-busy" *ngIf="loadingDocument">
                    <mat-progress-spinner diameter="24" class="spinner" color="accent" mode="indeterminate"></mat-progress-spinner>
                </div>
            </div>
        </div>
    
        <div class="details-container" *ngIf="nutritionalTableFacts?.length > 0">
            <span class="title" translate>lid.ext.pages._shared.nutritional-table.details</span>
            <div class="facts-container">
                <div class="actions">
                    <span class="action" [matTooltip]="'lid.ext.pages._shared.nutritional-table.findReplace' | translate" (click)="pasteAllFactsValues()">
                        <span class="spectrum-findreplace-icon"></span>
                    </span>
                </div>
                <div class="facts-list">
                    <div class="fact" *ngFor="let fact of nutritionalTableFacts">
                        <div class="fact-box detail">
                            <div class="text" [matTooltip]="fact.detail">{{fact.detail}}</div>
                            <span class="action" [matTooltip]="'lid.ext.pages._shared.nutritional-table.select' | translate" (click)="findText(fact.detail)" (click)="findText(fact.detail)">
                                <span class="spectrum-select-icon"></span>
                            </span>
                        </div>
                        <div class="fact-box key">
                            <span class="text" [matTooltip]="fact.key">{{fact.key}}</span>
                            <span class="action" [matTooltip]="'lid.ext.pages._shared.nutritional-table.select' | translate" (click)="findText(fact.key)" (click)="findText(fact.key)">
                                <span class="spectrum-select-icon"></span>
                            </span>
                        </div>
                        <div class="fact-box value">
                            <span class="text" [matTooltip]="fact.value">{{fact.value}}</span>
                            <span class="action" [matTooltip]="'lid.ext.pages._shared.nutritional-table.select' | translate" (click)="findText(fact.value)">
                                <span class="spectrum-select-icon"></span>
                            </span>
                        </div>
                        <div class="fact-status" [ngClass]="{'waiting': fact.status === 'waiting'}">
                            <mat-icon *ngIf="fact.status === 'waiting'" [matTooltip]="'lid.ext.pages._shared.nutritional-table.waitingForReplacement' | translate">schedule</mat-icon>
                            <mat-icon *ngIf="fact.status === 'not-found'" [matTooltip]="'lid.ext.pages._shared.nutritional-table.contentNotFound' | translate">warning</mat-icon>
                            <mat-icon *ngIf="fact.status === 'replaced'" [matTooltip]="'lid.ext.pages._shared.nutritional-table.contentFound' | translate">done</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
