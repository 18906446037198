import { Class } from './Class';
import { FieldType } from './FieldType';

export interface DocType
{
    id?: string;
    companyId: string;
    name: string;
    code?: string;
    classes: Class[];
    fieldTypes: FieldType[];
    isLibraryType?: boolean;
    isNutritionalTable?: boolean;

    // UI Only
    checked?: boolean;
}

export enum LibDocTypesNames
{
    imageTypeName = 'Library',
    fontTypeName = 'MoonFont'
}
