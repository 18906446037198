import { Injectable, EventEmitter, Inject } from '@angular/core';
import * as _ from 'underscore';
import { Class, ClassValue } from '../_models/configuration/Class';
import { FieldType } from '../_models/configuration/FieldType';
import { FileNameConfig } from '../_models/configuration/FileNameConfig';
import { AuthService } from './auth.service';
import { DocType } from '../_models/configuration/DocType';
import { EventHubService } from './event-hub.service';
import { Company } from '../_models/configuration/Company';
import { PlatformInfoProvider } from '../_dependencies/platform-info-provider';
import { Webhook, WebhookCall, WebhookCallsResponse } from '../_models/api/Webhooks';
import { CompanySubscriptionPlanUsageResponse, SubscriptionPlan } from '../public_api';

interface RestoreClassPost
{
  classId: string;
  restoreClassValues: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CompanyConfigurationService
{
  classValuesChanged: EventEmitter<Company> = new EventEmitter<Company>();

  constructor(private authService: AuthService,
    private eventHubService: EventHubService,
    @Inject('PlatformInfoProvider') private platformInfoProvider: PlatformInfoProvider)
  { }

  async getCompanies(): Promise<Company[]>
  {
    const result = await this.authService.authGet<Company[]>('/api/configuration/getcompanies');
    return result;
  }

  async getFullCompany(company: Company): Promise<Company>
  {
    const result = await this.authService.getFullCompany(company.id);
    return result;
  }

  async addCompany(company: Company): Promise<Company>
  {
    try
    {
      const result = await this.authService.authPost<Company>('/api/configuration/addcompany', company);
      await this.authService.refreshAssignments(result);
      return result;
    }
    catch (err)
    {
      console.log(`Error adding company: ${JSON.stringify(err)}`);
      throw Error(err.error);
    }
  }

  async updateCompany(company: Company): Promise<Company>
  {
    try
    {
      const result = await this.authService.authPost<Company>('/api/configuration/updatecompany', company);
      this.eventHubService.companyChanged.emit(result);
      return result;
    }
    catch (err)
    {
      console.log(`Error updating company: ${JSON.stringify(err)}`);
      throw Error(err.error);
    }
  }

  async updateCompanyForAdmin(company: Company, logo?: File): Promise<Company>
  {
    const formData = new FormData();
    if (logo)
    {
      formData.append('file', logo, logo.name);
    }
    formData.append('company', JSON.stringify(company));
    try
    {
      const result = await this.authService.longPost<Company>(`/api/configuration/updatecompanyforadmin`, formData, undefined);
      this.eventHubService.companyChanged.emit(result);
      return result;
    }
    catch (err)
    {
      console.log(`Error updating company: ${JSON.stringify(err)}`);
      throw Error(err.error);
    }
  }

  async restoreTaskNotificationConfigs(companyId: string): Promise<Company>
  {
    const params: [string, string][] = [
      ['companyId', companyId]
    ];
    const result = await this.authService.authGet<Class>('/api/configuration/restoreTaskNotificationConfigs', params);
    return result;
  }

  async deleteCompany(company: Company): Promise<boolean>
  {
    const params: [string, string][] = [['companyId', company.id]];
    const result = await this.authService.authDelete<boolean>('/api/configuration/deletecompany', params);
    await this.authService.refreshAssignments();
    return result;
  }

  getLogo(companyId: string): string
  {
    const url = `${this.platformInfoProvider.getBackendUrl()}/api/configuration/getLogo?companyId=${companyId}`;
    return url;
  }

  async getClasses(includeDeleted: boolean): Promise<Class[]>
  {
    const identity = this.authService.getCurrentIdentity();

    const params: [string, string][] = [
      ['companyId', identity.company?.id],
      ['includeDeleted', includeDeleted ? 'true' : 'false']
    ];
    const result = await this.authService.authGet<Class[]>('/api/configuration/getclasses', params);
    return result;
  }

  async getClass(classId: string): Promise<Class>
  {
    const params: [string, string][] = [
      ['classId', classId]
    ];
    const result = await this.authService.authGet<Class>('/api/configuration/getClass', params);
    return result;
  }

  async postClass(cls: Class): Promise<Class>
  {
    cls = _.clone(cls);
    cls.classValues = undefined;
    const result = await this.authService.authPost<Class>('/api/configuration/postClass', cls);
    return result;
  }

  async changeClassPosition(cls: Class, index: number): Promise<Class[]>
  {
    cls = _.clone(cls);
    cls.classValues = undefined;
    cls.order = index;
    const result = await this.authService.authPost<Class[]>('/api/configuration/changeClassPosition', cls);
    return result;
  }

  async deleteClass(cls: Class): Promise<void>
  {
    await this.authService.authPost<void>('/api/configuration/deleteClass', {id: cls.id, name: cls.name});
  }

  async restoreClass(classId: string, restoreClassValues: boolean): Promise<void>
  {
    const postData: RestoreClassPost =
    {
      classId: classId,
      restoreClassValues: restoreClassValues
    }
    await this.authService.authPost<void>('/api/configuration/restoreClass', postData);
  }

  async getClassValues(cls: Class): Promise<ClassValue[]>
  {
    const params: [string, string][] = [
      ['classId', cls.id]
    ];
    const result: ClassValue[] = await this.authService.authGet<ClassValue[]>('/api/configuration/getClassValues', params);
    return result;
  }

  async postClassValue(classValue: ClassValue): Promise<ClassValue>
  {
    const toSend = Object.assign({}, classValue);
    if (classValue.class)
    {
      toSend.class =
      {
        name: classValue.class.name,
        id: classValue.class.id,
        companyId: classValue.class.companyId,
        company: classValue.class.company
      };
    }
    const result: ClassValue = await this.authService.authPost<ClassValue>('/api/configuration/postClassValue', toSend);
    const company = this.authService.getCurrentIdentity().company;
    const cls = this.findClassRecursive(company.classes, result.classId);
    const oldValue = _.find(cls.classValues, cv => cv.id === result.id);
    if (oldValue !== undefined)
    {
      const index = cls.classValues.indexOf(oldValue);
      cls.classValues.splice(index, 1, result);
    }
    else
    {
      cls.classValues.push(result);
    }
    this.classValuesChanged.emit(company);
    return result;
  }

  async deleteClassValue(classValue: ClassValue): Promise<void>
  {
    const toSend: ClassValue =
    {
      id: classValue.id,
      class: undefined,
      name: classValue.name,
      code: classValue.code
    };
    await this.authService.authPost<void>('/api/configuration/deleteClassValue', toSend);
    const company = this.authService.getCurrentIdentity().company;
    const cls = this.findClassRecursive(company.classes, classValue.classId);
    const index = _.findIndex(cls.classValues, cv => cv.id === classValue.id);
    cls.classValues.splice(index, 1);
    this.classValuesChanged.emit(company);
  }

  private findClassRecursive(classes: Class[], classId: string): Class
  {
    for (let i = 0 ; i < classes.length ; i++)
    {
      if (classes[i].id === classId)
      {
        return classes[i];
      }
    }
    for (let i = 0 ; i < classes.length ; i++)
    {
      const subClass = this.findClassRecursive(classes[i].children, classId);
      if (subClass)
      {
        return subClass;
      }
    }
    return undefined;
  }

  async postFieldType(fieldType: FieldType): Promise<FieldType>
  {
    const result: FieldType = await this.authService.authPost<FieldType>('/api/configuration/postFieldType', fieldType);
    return result;
  }

  async deleteFieldType(fieldType: FieldType): Promise<void>
  {
    await this.authService.authPost('/api/configuration/deleteFieldType', fieldType);
  }

  async postFilenameConfigs(configs: FileNameConfig[]): Promise<FileNameConfig[]>
  {
    const result = await this.authService.authPost<FileNameConfig[]>('/api/configuration/postFilenameConfigs', configs);
    return result;
  }

  async postDocumentType(documentTypes: DocType[]): Promise<DocType[]>
  {
    // make sure we just send what's needed, and no recursive data because of child/parent relationships of classes
    const toSent = _.map(documentTypes, dt =>
      <DocType> {
        id: dt.id,
        name: dt.name,
        code: dt.code,
        isLibraryType: dt.isLibraryType,
        companyId: dt.companyId,
        classes: _.map(dt.classes, cls => <Class>{id: cls.id, company: cls.company, name: cls.name}),
        fieldTypes: _.map(dt.fieldTypes, ft => <FieldType>{id: ft.id, name: ft.name, type: ft.type})
      });
    const result = await this.authService.authPost<DocType[]>('/api/configuration/postDocumentTypes', toSent);
    return result;
  }

  async deleteDocumentType(documentType: DocType): Promise<void>
  {
    await this.authService.authPost('/api/configuration/deleteDocumentType', documentType);
  }

  async getApiKey(companyId: string): Promise<string>
  {
    const params: [string, string][] = [
      ['companyId', companyId]
    ];
    const result = await this.authService.authGet<any>('/api/configuration/getApiKey', params);
    return result;
  }

  async generateApiSecret(company: Company): Promise<string>
  {
    const lightCompany: Company = {id: company.id, name: company.name};
    const apiKey = await this.authService.authPost<string>('/api/configuration/generateApiSecret', lightCompany);
    return apiKey;
  }

  async deleteApiSecret(companyId: string): Promise<void>
  {
    const params: [string, string][] = [
      ['companyId', companyId]
    ];
    await this.authService.authDelete('/api/configuration/deleteApiSecret', params);
  }

  async getApiSwaggerServiceUrl(): Promise<string>
  {
    const result = await this.authService.authGet<any>('/api/configuration/getApiSwaggerServiceUrl');
    return result;
  }

  async getWebhooks(): Promise<Webhook[]>
  {
    const companyId = this.authService.getCurrentIdentity().company.id;
    const params: [string, string][] = [
      ['companyId', companyId]
    ];
    const result = await this.authService.authGet<Webhook[]>('/api/configuration/getWebhooks', params);
    return result;
  }

  async createWebhook(webhook: Webhook): Promise<Webhook>
  {
    const companyId = this.authService.getCurrentIdentity().company.id;
    webhook.companyId = companyId;
    const result = await this.authService.authPost<Webhook>('/api/configuration/createWebhook', webhook);
    return result;
  }

  async updateWebhook(webhook: Webhook): Promise<Webhook>
  {
    const companyId = this.authService.getCurrentIdentity().company.id;
    webhook.companyId = companyId;
    const result = await this.authService.authPost<Webhook>('/api/configuration/updateWebhook', webhook);
    return result;
  }

  async deleteWebhook(webhook: Webhook): Promise<void>
  {
    const params: [string, string][] = [
      ['webhookId', webhook.id]
    ];
    await this.authService.authDelete<void>('/api/configuration/deleteWebhook', params);
  }

  async getWebhookCalls(webhookId: string, page: number): Promise<WebhookCallsResponse>
  {
    const params: [string, string][] = [
      ['webhookId', webhookId],
      ['page', `${page}`]
    ];
    const response = await this.authService.authGet<WebhookCallsResponse>('/api/configuration/getWebhookCalls', params);
    this.fixTimestamps(...response.result);
    return response;
  }

  async queueTestCall(webhookId: string): Promise<WebhookCall>
  {
    const params: [string, string][] = [
      ['webhookId', webhookId]
    ];
    const result = await this.authService.authGet<WebhookCall>('/api/configuration/queueTestCall', params);
    this.fixTimestamps(result);
    return result;
  }

  async getDefaultTestWebhookUrl(): Promise<string>
  {
    const result = await this.authService.authGet<string>('/api/configuration/getDefaultTestWebhookUrl');
    return result;
  }

  async restartCall(call: WebhookCall): Promise<WebhookCall>
  {
    const params: [string, string][] = [
      ['webhookCallId', call.id]
    ];
    const result = await this.authService.authGet<WebhookCall>('/api/configuration/restartCall', params);
    this.fixTimestamps(result);
    return result;
  }

  private fixTimestamps(...calls: WebhookCall[])
  {
    calls.forEach(call =>
    {
      if (call.timestampUtc)
      {
        call.timestampUtc = new Date(call.timestampUtc);
      }
      if (call.lastIntentUtc)
      {
        call.timestampUtc = new Date(call.timestampUtc);
      }
    });
  }

  async getSubscriptionPlans(): Promise<SubscriptionPlan[]>
  {
    const result = await this.authService.authGet<SubscriptionPlan[]>('/api/configuration/getSubscriptionPlans');
    return result;
  }

  async getSubscriptionPlanUsage(companyId: string): Promise<CompanySubscriptionPlanUsageResponse>
  {
    const params: [string, string][] = [
      ['companyId', companyId]
    ];
    const result = await this.authService.authGet<CompanySubscriptionPlanUsageResponse>('/api/configuration/getSubscriptionPlanUsage', params);
    return result;
  }
}
