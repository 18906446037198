import { Injectable } from '@angular/core';
import { Subscription } from '../_models/subscriptions/Subscription';
import { AuthService } from './auth.service';

export interface ActivateMicrosoftSubPost
{
  subscription: Subscription;
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService
{
  constructor(private authService: AuthService)
  {
  }

  async getSubscriptionForMicrosoftToken(billingToken: string): Promise<Subscription>
  {
    const params: [string, string][] = [
      ['billingToken', billingToken]
    ];
    const result = await this.authService.get<any>('apisub/microsoft/GetSubscriptionForToken', params);
    return result;
  }

  async getUserSubscriptions(): Promise<Subscription[]>
  {
    const result = await this.authService.authGet<Subscription[]>('apisub/subscriptions/getUserSubscriptions');
    return result;
  }

  async activateMicrosoftSubscription(subscription: Subscription, token: string): Promise<Subscription>
  {
    const post: ActivateMicrosoftSubPost =
    {
      subscription: subscription,
      token: token
    };
    const result = await this.authService.authPost<Subscription>('apisub/microsoft/activateMicrosoftSubscription', post);
    return result;
  }

  async resetTestSubscription()
  {
    await this.authService.authPost<void>('apisub/subscriptions/resetTestSubscription', null);
  }
}
