import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { RuleConfiguration, RuleIDs } from '../_models/rules/RuleConfiguration';
import { MoonDeskDocument } from '../_models/document/Document';
import { RuleClassSelection, RuleFilter } from '../_models/rules/RuleFilters';
import { RulesResponse } from '../_models/rules/RulesResponse';
import { RuleResult } from '../_models/rules/RuleResult';
import { RuleResultChange } from '../_models/rules/RuleResultChange';
import { RuleLibraryImageDto } from '../_modelsDTO/rules/RuleLibraryImageDto';

interface DownloadRuleReportPost
{
  ruleFilter: RuleFilter;
  timezoneOffset: number;
}

interface RecoverRuleConfigPost
{
  ruleConfigId: string;
  recoverLibraryImages: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RulesService
{

  constructor(private authService: AuthService)
  { }

  async webDownloadRulesReport(filter: RuleFilter): Promise<void>
  {
    let url: string;
    try
    {
      url = await this.getRuleReportUrl(filter);
      const fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = `Rules_${this.authService.getCurrentIdentity().company.name}.xlsx`;
      fileLink.click();
    }
    finally
    {
      if (url)
      {
        window.URL.revokeObjectURL(url);
      }
    }
  }

  async getRuleReportUrl(filter: RuleFilter): Promise<string>
  {
    const postData: DownloadRuleReportPost =
    {
      ruleFilter: filter,
      timezoneOffset: new Date().getTimezoneOffset()
    };
    const data = await this.authService.authPost<Blob>('/api/rules/downloadRulesReport', postData, true);
    return window.URL.createObjectURL(data);
  }

  async getRuleConfig(id: string): Promise<RuleConfiguration>
  {
    const params: [string, string][] = [
      ['ruleConfigId', id]
    ];
    const result = await this.authService.authGet<RuleConfiguration>('/api/rules/getRuleConfig', params);
    this.fixRuleTimestamp([result]);
    return result;
  }

  async getRulesConfigs(document: MoonDeskDocument): Promise<RuleConfiguration[]>
  {
    const ruleFilter: RuleFilter =
    {
      companyId: document.companyId,
      documentTypesIds: document.documentType ? [document.documentType.id] : undefined,
      classSelections: document.classValues ? document.classValues.map(cv => <RuleClassSelection>{classValueId: cv.id}) : []
    };
    const result = await this.authService.authPost<RuleConfiguration[]>('/api/rules/getRulesConfigs', ruleFilter);
    return result;
  }

  async searchRuleConfigs(ruleFilter: RuleFilter): Promise<RulesResponse>
  {
    const result = await this.authService.authPost<RulesResponse>('/api/rules/searchRuleConfigs', ruleFilter);
    this.fixRuleTimestamp(result.result);
    return result;
  }

  async archiveRuleConfig(rule: RuleConfiguration)
  {
    await this.authService.authPost<void>('/api/rules/archiveRuleConfig', rule);
  }

  async recoverRuleConfig(rule: RuleConfiguration, recoverLibraryImages: boolean)
  {
    const postData: RecoverRuleConfigPost =
    {
      ruleConfigId: rule.id,
      recoverLibraryImages: rule.ruleID === RuleIDs.LIBRARY_CONTENT ? recoverLibraryImages : false
    }
    await this.authService.authPost<void>('/api/rules/recoverRuleConfig', postData);
  }

  async getArchivedRuleImages(ruleConfigId: string): Promise<RuleLibraryImageDto[]>
  {
    const result = await this.authService.authGet<RuleLibraryImageDto[]>(
      '/api/v2/rules/getRuleArchivedImages',
      [['ruleConfigId', ruleConfigId]]);
    return result;
  }

  async postRuleConfigs(ruleConfigs: RuleConfiguration[]): Promise<RuleConfiguration[]>
  {
    const result = await this.authService.authPost<RuleConfiguration[]>('/api/rules/postRuleConfigs', ruleConfigs);
    return result;
  }

  async deleteRuleConfig(ruleConfig: RuleConfiguration): Promise<void>
  {
    await this.authService.authPost('/api/rules/deleteRuleConfig', ruleConfig);
  }

  async cloneRuleConfig(ruleConfigId: string): Promise<RuleConfiguration>
  {
    const params: [string, string][] = [
      ['ruleConfigId', ruleConfigId]
    ];
    const result = await this.authService.authGet<RuleConfiguration>('/api/rules/cloneRuleConfig', params);
    return result;
  }

  async getExistingRuleTagsNames(): Promise<string[]>
  {
    const identity = this.authService.getCurrentIdentity();
    const params: [string, string][] = [
      ['companyId', identity.company.id]
    ];
    const result = await this.authService.authGet<string[]>('/api/rules/getExistingRuleTagsNames', params);
    return result;
  }

  private fixRuleTimestamp(ruleConfigurations: RuleConfiguration[])
  {
    for (const ruleConfig of ruleConfigurations)
    {
      ruleConfig.lastModificationTimestampUtc = new Date(ruleConfig.lastModificationTimestampUtc);
      ruleConfig.creationTimestampUtc = new Date(ruleConfig.creationTimestampUtc);
    }
  }

  async postRuleResultChange(ruleResultChange: RuleResultChange): Promise<RuleResult>
  {
    const result = await this.authService.authPost<RuleResult>('/api/rules/postRuleResultChange', ruleResultChange);
    return result;
  }

  async getRuleResultsBySubTask(subtaskId: string): Promise<RuleResult[]>
  {
    const params: [string, string][] = [
      ['subtaskId', subtaskId]
    ];
    const result = await this.authService.authGet<RuleResult[]>('/api/rules/getRuleResultsBySubTask', params);
    return result;
  }

  async getRuleResultChangesByRuleNumber(documentVersionId: string, ruleNumber: number): Promise<RuleResultChange[]>
  {
    const params: [string, string][] = [
      ['documentVersionId', documentVersionId],
      ['ruleNumber', ruleNumber.toString()]
    ];
    const result = await this.authService.authGet<RuleResultChange[]>('/api/rules/getRuleResultChanges', params);
    return result;
  }

  async getRuleResultChangesById(ruleResultId: string): Promise<RuleResultChange[]>
  {
    const params: [string, string][] = [
      ['ruleResultId', ruleResultId]
    ];
    const result = await this.authService.authGet<RuleResultChange[]>('/api/rules/getRuleResultChangesById', params);
    return result;
  }

  async getInReviewDocumentNamesAffectedByRuleClassification(ruleConfiguration: RuleConfiguration): Promise<string[]>
  {
    const postData: RuleConfiguration =
    {
      id: ruleConfiguration.id,
      companyId: ruleConfiguration.companyId,
      ruleID: ruleConfiguration.ruleID,
      ruleOptions: '{}',
      ruleDocumentTypes: ruleConfiguration.ruleDocumentTypes,
      ruleClassValues: ruleConfiguration.ruleClassValues,
    };
    const result = await this.authService.authPost<string[]>(
      '/api/rules/GetInReviewDocumentNamesAffectedByRuleClassification', postData);
    return result;
  }
}
