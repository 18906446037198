import { Injectable, EventEmitter } from '@angular/core';
import { MoonDeskDocument } from '../_models/document/Document';
import { MoonTask } from '../_models/tasks/MoonTask';
import { SubTask } from '../_models/tasks/SubTask';
import { Company } from '../_models/configuration/Company';
import { TaskNotification } from '../_models/notifications/TaskNotification';
import { SupportMessage } from '../_models/support/SupportMessage';
import { SilentMessage } from '../_models/support/SilentMessage';
import { AdobeAnnotationData } from '../_models/tasks/SubTaskMessage';
import { TrackTraceEvent } from './logging.service';
import { KeyValue } from '@angular/common';

export interface TaskView
{
  task: MoonTask;
  subTask?: SubTask;
  selectedDocumentId?: string;
  /**
   * Select first queue element after the queue sorting.
   * If selectedDocumentId != null this will be ignored
   */
  selectFirst?: boolean;
  tabIndex?: number;
}

export interface TaskDescriptionPopupData
{
  taskId: string;
  subTaskId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class EventHubService
{
  /**
   * COMPANY
   * @event companyChanged
   * emited by companyConfiguration when update company
   * listen by authService for update identity
   */
  public companyChanged: EventEmitter<Company> = new EventEmitter<Company>();

  /**
   * PICTURE VIEWER AND DOCUMENT CARD
   * @event goToEditDocuments
   * emited by documentCard and pictureViewer when editing a doc
   * listen by navigationService(EXT) for navigate to the edit page
   * @event downloadDocument
   * emited by pictureComponent(WEB) for download a document version
   * listen by TODO - should be used to download a document version in WEB
   */
  public goToEditDocuments: EventEmitter<MoonDeskDocument[]> = new EventEmitter<MoonDeskDocument[]>();
  public downloadDocument: EventEmitter<MoonDeskDocument> = new EventEmitter<MoonDeskDocument>();

  // WORK
  public goToWorkEdit: EventEmitter<MoonDeskDocument[] | TaskView> = new EventEmitter<MoonDeskDocument[] | TaskView>();
  public goToWorkImport: EventEmitter<TaskView> = new EventEmitter<TaskView>();

  // TASKS
  public openTaskView: EventEmitter<TaskDescriptionPopupData> = new EventEmitter<TaskDescriptionPopupData>();
  public editSubTask: EventEmitter<TaskView> = new EventEmitter<TaskView>();
  public refreshTasks: EventEmitter<void> = new EventEmitter<void>();
  public importToTask: EventEmitter<MoonTask> = new EventEmitter<MoonTask>();
  public goToTaskWebsite: EventEmitter<TaskView> = new EventEmitter<TaskView>();

  // SEARCH
  public searchLinkedParentDocuments: EventEmitter<KeyValue<string, string>> = new EventEmitter<KeyValue<string, string>>();

  // DOCUMENT CARD
  public openDownloadDialog: EventEmitter<MoonDeskDocument[]> = new EventEmitter<MoonDeskDocument[]>();

  // WELCOME PAGE
  public openTutorial: EventEmitter<void> = new EventEmitter<void>();
  public openNewsDialog: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Toggle the sidenav and optionally show the 'Register' section directly
   */
  public toggleSideNav: EventEmitter<'Register' | undefined> = new EventEmitter<'Register' | undefined>();

  // NOTIFICATIONS
  public taskMessageClicked: EventEmitter<TaskNotification> = new EventEmitter<TaskNotification>();
  public supportMessageClicked: EventEmitter<SupportMessage> = new EventEmitter<SupportMessage>();

  // SEARCH
  public changeBulkInfos: EventEmitter<MoonDeskDocument[]> = new EventEmitter<MoonDeskDocument[]>();

  // SUPPORT
  openSupportClicked: EventEmitter<void> = new EventEmitter<void>();
  silentSupportMessage: EventEmitter<SilentMessage> = new EventEmitter<SilentMessage>();
  loadHubSpotSupportWidget: EventEmitter<void> = new EventEmitter<void>();

  // WEB 2.0 TASK EDITOR
  clenaUpFiles: EventEmitter<void> = new EventEmitter<void>();
  openSubTask: EventEmitter<MoonDeskDocument> = new EventEmitter<MoonDeskDocument>();
  navigateTab: EventEmitter<'NEXT' | 'PREVIOUS'> = new EventEmitter<'NEXT' | 'PREVIOUS'>();
  // Toggle Sidenav used to emit from Frame
  sidenavToggled: EventEmitter<void> = new EventEmitter<void>();
  hubspotWidgedOpenStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  hubspotUnreadMessages: EventEmitter<number> = new EventEmitter<number>();
  toggleSupport: EventEmitter<void> = new EventEmitter<void>();
  documentToShare: EventEmitter<MoonDeskDocument> = new EventEmitter<MoonDeskDocument>();
  documentToDownload: EventEmitter<MoonDeskDocument> = new EventEmitter<MoonDeskDocument>();

  /**
   * TASK REVIEW
   * TODO - CREATE OWN 'HELPER-SERVICE' FOR THIS?
   * https://ciclosoftware.visualstudio.com/YGContras/_sprints/taskboard/Team%20MoonDesk/YGContras/Sprint%2083?workitem=12859)
   */

  taskReviewFocusChat: EventEmitter<void | AdobeAnnotationData> = new EventEmitter<void | AdobeAnnotationData>();
  taskHistoryMessageSelected: EventEmitter<string> = new EventEmitter<string>();
  adobeMarkSelected: EventEmitter<null | string> = new EventEmitter<null | string>();
  taskReviewCommentSaved: EventEmitter<void> = new EventEmitter<void>();
  oldMarkSelected: EventEmitter<null | string> = new EventEmitter<null | string>();
  taskReviewAnnotationUpdated: EventEmitter<AdobeAnnotationData> = new EventEmitter<AdobeAnnotationData>();
  taskReviewCancelComment: EventEmitter<void> = new EventEmitter<void>();
  adobeSearchText: EventEmitter<string> = new EventEmitter<string>();
  taskReviewMarkMsgClicked: EventEmitter<'none' | 'note' | 'draw' | 'underline' | 'strikethrough' | 'highlight'> =
    new EventEmitter<'none' | 'note' | 'draw' | 'underline' | 'strikethrough' | 'highlight'>();
  taskObjectUpdated: EventEmitter <void> = new EventEmitter<void>();
  taskReviewedOnPopupClose: EventEmitter <MoonTask> = new EventEmitter<MoonTask>();

  // Adobe PDF Viewer available events
  adobeNextResult: EventEmitter<void> = new EventEmitter<void>();
  adobePreviousResult: EventEmitter<void> = new EventEmitter<void>();
  adobeResultLabel: EventEmitter<string> = new EventEmitter<string>();

  // Extended Scripts Logging
  extendedScriptLoggging: EventEmitter<TrackTraceEvent> = new EventEmitter<TrackTraceEvent>();

  // AI MoonDesk
  closeAiPopup: EventEmitter<void> = new EventEmitter<void>();
}
