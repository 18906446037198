import { Injectable, EventEmitter } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { AuthService } from './auth.service';
import { Identity } from '../_models/user/Identity';

export interface SessionTimeout
{
  /**
   * True if the user is idle (after the configured time on company level)
   */
  idle: boolean;
  /**
   * The time left until timeout
   */
  timeoutWarning: number;
  /**
   * When timeout happened, do the logout in the main component
   */
  timeout: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SessionTimeoutService
{
  public sessionTimeout: EventEmitter<SessionTimeout> = new EventEmitter<SessionTimeout>();

  constructor(private idle: Idle,
              private authService: AuthService)
  {
    this.idle.onIdleStart.subscribe(() => this.idleStart());
    this.idle.onIdleEnd.subscribe(() => this.idleEnd());
    this.idle.onTimeout.subscribe(() => this.timeout());
    this.idle.onTimeoutWarning.subscribe(t => this.timeoutWarning(t));
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.authService.identityChanged.subscribe(id => this.updateIdentity(id));
    this.updateIdentity(this.authService.getCurrentIdentity());
  }

  resetIdle()
  {
    if (this.idle.isRunning())
    {
      this.idle.stop();
      this.idle.watch();
    }
  }

  private updateIdentity(id: Identity)
  {
    if (!id || !id.company || !id.company.sessionTimeout || id.company.sessionTimeout <= 0)
    {
      this.idle.stop();
      this.sessionTimeout.emit({idle: false, timeoutWarning: 0, timeout: false});
    }
    else
    {
      const idle = id.company.sessionIdle && id.company.sessionIdle > 0 ? id.company.sessionIdle : 1;
      this.idle.stop();
      this.idle.setIdle(idle);
      this.idle.setTimeout(id.company.sessionTimeout);
      this.idle.watch();
    }
  }

  private idleStart()
  {
    console.log('idleStart');
    this.sessionTimeout.emit({idle: true, timeoutWarning: 0, timeout: false});

  }

  private idleEnd()
  {
    console.log('idleEnd');
    this.sessionTimeout.emit({idle: false, timeoutWarning: 0, timeout: false});
  }

  private timeoutWarning(time: number)
  {
    console.log('timeoutWarning: ' + time);
    this.sessionTimeout.emit({idle: true, timeoutWarning: time, timeout: false});
  }

  private timeout()
  {
    console.log('timeout');
    this.sessionTimeout.emit({idle: true, timeoutWarning: 0, timeout: true});
  }
}
