import { Component, Input } from '@angular/core';
import * as _ from 'underscore';
import {
  User,
  AuthService,
  FeedbackService
} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent
{
  _user: User;
  unconfirmedEmail: string;

  @Input() set user(user: User) {
    if (user)
    {
      this._user = _.clone(user);
      this._user.password = null;
      this._user.repeatPassword = null;
      if (this.unconfirmedEmail)
      {
        this._user.email = this.unconfirmedEmail;
      }
    }
    else
    {
      this._user = undefined;
    }
  }

  profileBusy: boolean = false;
  profileMessage: string;

  constructor(
    private authService: AuthService,
    private feedbackService: FeedbackService)
  { }



  async save() {
    if (this.profileBusy) {
      return;
    }

    try {
      this.profileBusy = true;
      this.profileMessage = undefined;
      if (this._user.password !== this._user.repeatPassword)
      {
        this.profileMessage = 'New Passwords do not match';
        return;
      }
      const response = await this.authService.updateProfile(this._user);
      this.profileMessage = response.error;
      if (response.user && !response.error)
      {
        if (response.user.email !== this._user.email)
        {
          this.feedbackService.notifyMessage('Profile saved. Please check your email for a confirmation link');
          this.unconfirmedEmail = this._user.email;
        }
        else
        {
          this.feedbackService.notifyMessage('Profile saved');
        }
        return;
      }
      else if (response.error)
      {
        this.feedbackService.notifyMessage('Error: ' + response.error);
      }
    }
    catch (err) {
      console.log(`Unexpected error updating profile: ${err}`);
    }
    finally {
      this.profileBusy = false;
    }
  }
}
