/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, NgZone, Inject, EventEmitter, OnDestroy } from '@angular/core';
import * as _ from 'underscore';
import { AuthService } from './auth.service';
import { LoggingService, Severity } from './logging.service';
import { Identity } from '../_models/user/Identity';
import { PushMessage } from '../_models/notifications/PushMessage';
import { PlatformInfoProvider } from '../_dependencies/platform-info-provider';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';

export enum Channels
{
  TaskMessageChannel = 'TaskMessageChannel',
  SupportMessageChannel = 'SupportMessageChannel',
  SupportInfoRequestChannel = 'SupportInfoRequestChannel',
  SlackSupportChannel = 'SlackSupportChannel',
  CompanyUpdateChannel = 'CompanyUpdateChannel',
  RuleResultUpdateChannel = 'RuleResultUpdateChannel'
}

export interface PushMessageFilter
{
  companyId: string;
  read: 'all' | 'read' | 'unread';
  archived: 'all' | 'archived' | 'unarchived';
  page?: number;
  userId?: string;
  reviewerId?: string;
  minDate?: Date;
  maxDate?: Date;
  onlyTaskMessages?: boolean;
  /**
   * Only if onlyTaskMessages == true
   */
  taskNumber?: number;
}

export interface PushMessageResponse
{
  page: number;
  pageCount: number;
  pageSize: number;
  totalResult: number;
  messages: PushMessage[];
}

export enum NotificationConnectionStates
{
  initializing = 0,
  disconnected = 1,
  connecting = 2,
  connected = 3,
  failed = 4
}

@Injectable({
  providedIn: 'root'
})
export class NotificationHubService implements OnDestroy
{
  private hubConnection: HubConnection;
  private loop: number = 0;

  private url: string;
  private readonly maxReconnectTrials: number = 4;

  connectionState: NotificationConnectionStates = NotificationConnectionStates.initializing;
  allMessages: PushMessage[] = [];

  messageUpdate: EventEmitter<void> = new EventEmitter<void>();
  connectedChange = new EventEmitter<NotificationConnectionStates>();
  taskMessagePushed = new EventEmitter<PushMessage>();
  supportMessagePushed = new EventEmitter<PushMessage>();
  supportInfoRequestPushed = new EventEmitter<PushMessage>();
  companyUpdateChannelPushed = new EventEmitter<PushMessage>();
  ruleResultPushed = new EventEmitter<PushMessage>();

  private changeConnected(c: NotificationConnectionStates)
  {
    this.logger.trackTrace(`NotificationHubService status: ${c}`, Severity.Information);
    this.connectionState = c;
    this.connectedChange.emit(c);
  }

  constructor(private authService: AuthService,
    @Inject('PlatformInfoProvider') private platformInfoProvider: PlatformInfoProvider,
    private zone: NgZone,
    private logger: LoggingService)
  {
    this.authService.identityChanged.subscribe(identity => this.updatePushHub(identity));
    this.updatePushHub(this.authService.getCurrentIdentity());
    this.companyUpdateChannelPushed.subscribe(() =>
    {
      this.authService.updateCompany();
    });
    this.url = this.platformInfoProvider.getBackendUrl();
  }

  ngOnDestroy(): void
  {
    this.loop = 0;
    this.disconnectPushHub();
  }

  private updatePushHub = async (identity: Identity): Promise<void> =>
  {
    try
    {
      await this.disconnectPushHub();

      if (!identity)
      {
        this.logger.trackTrace('No identity for push notifications', Severity.Warning);
        return;
      }

      const token = identity.user ? identity.user.token : identity.reviewer.token;
      this.changeConnected(NotificationConnectionStates.connecting);

      const options: IHttpConnectionOptions =
      {
        accessTokenFactory: () => token
      };

      const hubConnBuilder = new HubConnectionBuilder().withUrl(`${this.url}/api/hubs/notifications`, options);
      this.hubConnection = hubConnBuilder.build();

      this.configureHubConnectionEvents();
      this.connectChannels();

      await this.hubConnection.start();
      this.changeConnected(NotificationConnectionStates.connected);
      await this.refreshMessages();
    }
    catch (err)
    {
      this.changeConnected(NotificationConnectionStates.failed);
      console.log('Error updating PushHubConnection', err);

      if (this.loop === 0)
      {
        this.reconnectLoop();
      }
    }
  };

  private configureHubConnectionEvents(): void
  {
    this.hubConnection.onclose(err =>
    {
      this.zone.run(() =>
      {
        this.changeConnected(NotificationConnectionStates.failed);
        this.logger.trackTrace(`PushHubConnection was closed: ${err}`, Severity.Information);
      });
    });
  }


  private async reconnectLoop(): Promise<void>
  {
    this.loop = 1;

    while (this.shouldContinueReconnecting())
    {
      try
      {
        this.logger.trackTrace(`Trying to reestablish notifications connection (Attempt ${this.loop})`);
        await this.updatePushHub(this.authService.getCurrentIdentity());
      }
      catch (err)
      {
        this.logger.trackTrace(`Reconnection attempt failed: ${err}`, Severity.Error);
      }
      finally
      {
        this.loop++;
        if (this.shouldContinueReconnecting())
        {
          await this.delay(5000);
        }
      }
    }

    this.loop = 0;
  }

  private shouldContinueReconnecting(): boolean
  {
    const currentIdentity = this.authService.getCurrentIdentity();

    return (
      this.loop > 0 &&
      this.loop <= this.maxReconnectTrials &&
      this.connectionState !== NotificationConnectionStates.connected &&
      ((currentIdentity?.user || currentIdentity?.reviewer) ? true : false)
    );
  }

  private delay(ms: number)
  {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private connectChannels()
  {
    this.hubConnection.on(Channels.TaskMessageChannel, msg => this.processPush(msg, this.taskMessagePushed));
    this.hubConnection.on(Channels.SlackSupportChannel, msg => this.processPush(msg, this.supportMessagePushed));
    this.hubConnection.on(Channels.SupportMessageChannel, msg => this.processPush(msg, this.supportMessagePushed));
    this.hubConnection.on(Channels.SupportInfoRequestChannel, msg => this.processPush(msg, this.supportInfoRequestPushed));
    this.hubConnection.on(Channels.CompanyUpdateChannel, msg => this.processPush(msg, this.companyUpdateChannelPushed));
    this.hubConnection.on(Channels.RuleResultUpdateChannel, msg => this.processPush(msg, this.ruleResultPushed));
  }

  private processPush(msg: string, emitter: EventEmitter<PushMessage>)
  {
    this.zone.run(() =>
    {
      try
      {
        const messageObj = <PushMessage>JSON.parse(msg);
        this.processMessages([messageObj]);
        emitter.emit(messageObj);
      }
      catch (err)
      {
        this.logger.logException(err);
      }
    });
  }

  async allMessagesRead()
  {
    const cid = this.authService.getCurrentIdentity().company.id;
    const params: [string, string][] = [
      ['companyId', cid]
    ];
    // let's not await, this can take long (backend to be optimized)
    this.authService.authGet('/api/notification/MarkAllAsRead', params);
    _.forEach(this.allMessages, msg => msg.read = true);
    this.messageUpdate.emit();
  }

  async markAsRead(msgs: PushMessage[])
  {
    // non-persistent messages don't have id's and don't have to be marked
    const allIds = _.map(msgs, msg => msg.id);
    const ids = _.filter(allIds, id => id !== undefined);
    if (ids.length === 0)
    {
      return;
    }
    await this.authService.authPost('/api/notification/markAsRead', ids);
    ids.forEach(id =>
    {
      const msg = _.find(this.allMessages, m => m.id === id);
      msg.read = true;
    });
    this.messageUpdate.emit();
  }

  async markAsUnRead(msgs: PushMessage[])
  {
    const ids = _.map(msgs, msg => msg.id);
    await this.authService.authPost('/api/notification/markAsUnRead', ids);
    ids.forEach(id =>
    {
      const msg = _.find(this.allMessages, m => m.id === id);
      msg.read = false;
    });
    this.messageUpdate.emit();
  }

  async archiveAllMessages()
  {
    const cid = this.authService.getCurrentIdentity().company.id;
    const params: [string, string][] = [
      ['companyId', cid]
    ];
    // let's not await, this can take long (backend to be optimized)
    this.authService.authGet('/api/notification/MarkAllAsArchived', params);
    this.allMessages = [];
    this.messageUpdate.emit();
  }

  async archive(msgs: PushMessage[])
  {
    const ids = _.map(msgs, msg => msg.id);
    await this.authService.authPost('/api/notification/archive', ids);
    ids.forEach(id =>
    {
      const index = _.findIndex(this.allMessages, m => m.id === id);
      this.allMessages.splice(index, 1);
    });
    this.messageUpdate.emit();
  }

  async refreshMessages(manualRefresh?: boolean)
  {
    const id = this.authService.getCurrentIdentity();
    if (id)
    {
      if(this.connectionState === NotificationConnectionStates.failed && this.loop === 0 && manualRefresh)
      {
        this.reconnectLoop();
        return;
      }
      const msgs = await this.getMessages('unarchived', 'all');
      this.logger.trackTrace(`Got ${msgs.length} messages`, Severity.Information);
      this.processMessages(msgs);
    }
  }

  private async getMessages(archived: 'all' | 'archived' | 'unarchived', read: 'all' | 'read' | 'unread'): Promise<PushMessage[]>
  {
    // TODO CONSIDER TOTALRESULTS! NOW THE UI SHOWS MAX 20 MESSAGES
    // TODO CONSIDER PAGE LOAD WHEN SCROLLING DOWN
    const id = this.authService.getCurrentIdentity();
    const companyId = id && id.company ? id.company.id : undefined;
    const filter: PushMessageFilter =
    {
      archived: archived,
      read: read,
      companyId: companyId,
      page: 1
    };
    this.allMessages = [];
    const msgs = await this.authService.authPost<PushMessageResponse>('/api/notification/getMessagePage', filter);
    return msgs.messages;
  }

  async searchPushMessages(filter: PushMessageFilter): Promise<PushMessageResponse>
  {
    const result = await this.authService.authPost<PushMessageResponse>('/api/notification/searchPushMessages', filter);
    return result;
  }

  private processMessages(msgs: PushMessage[])
  {
    msgs.forEach((msg: PushMessage) =>
    {
      try
      {
        const id: Identity = this.authService.getCurrentIdentity();
        // we just process the message if
        // - there is a user AND
        // - the message is for any company OR the current company is the right one
        if (id && (id.user || id.reviewer) && (!msg.companyId || id.company && msg.companyId === id.company.id))
        {
          msg.timestampUtc = new Date(msg.timestampUtc);
          switch (msg.channel)
          {
          case Channels.TaskMessageChannel:
            msg.taskNotification = JSON.parse(msg.jsonObject);
            if (msg.taskNotification.subTaskMessage)
            {
              msg.taskNotification.subTaskMessage.timestampUtc = new Date(msg.taskNotification.subTaskMessage.timestampUtc);
            }
            break;
          case Channels.SlackSupportChannel:
          case Channels.SupportMessageChannel:
            msg.supportMessage = JSON.parse(msg.jsonObject);
            break;
          case Channels.RuleResultUpdateChannel:
            msg.ruleResultChange = JSON.parse(msg.jsonObject);
            break;
          case Channels.SupportInfoRequestChannel:
          case Channels.CompanyUpdateChannel:
            // not needed, the processPush() function sends an event...
            break;
          default:
            this.logger.logException(new Error('Unknown message channel: ' + msg.channel));
          }
          if(!_.any(this.allMessages, message => msg.id && msg.id === message.id))
          {
            this.allMessages.push(msg);
          }
        }
      }
      catch (err)
      {
        this.logger.trackTrace(`Error processing PushMessage`, Severity.Information);
        this.logger.logException(err);
      }
    });
    this.messageUpdate.emit();
  }

  private disconnectPushHub = async () =>
  {
    if (this.hubConnection)
    {
      try
      {
        this.logger.trackTrace('Disconnecting PushHubConnection', Severity.Information);
        await this.hubConnection.stop();
        this.allMessages = [];
        this.messageUpdate.emit();
        this.logger.trackTrace('PushHubConnection stopped', Severity.Information);
      }
      catch (err)
      {
        this.logger.logException(err);
      }
      finally
      {
        this.hubConnection = undefined;
        this.changeConnected(NotificationConnectionStates.disconnected);
      }
    }
  };
}
