import { Company } from '../configuration/Company';
import { User } from '../user/User';
import { SubTask } from './SubTask';
import { FindAndReplacePair } from '../document/FindAndReplacePair';
import { MoonTaskAudit } from './MoonTaskAudit';
import { ReviewerAssignment } from './Reviewer';
import { ContentGroup, DocumentContent } from './DocumentContent';
import { ExportFilesIdentifiers } from '../document/DocumentVersion';
import { TaskInfos } from './MoonTaskInfos';
import { TaskProject } from './TaskProject';
import { TaskDocumentTemplate } from './TaskDocumentTemplate';
import { TaskMessage } from './TaskMessage';
import { DocType } from '../configuration/DocType';
import { ClassValue } from '../configuration/Class';
import { NutritionalTable } from '../nutritionalTable/NutritionalTable';

export enum MoonTaskType
{
  DocumentChange = 0,
  DocumentReview = 1,
  DocumentShare = 2
}

export interface MoonTaskAttachment
{
    name: string;
    isLocalStorage: boolean;
    cloneTaskId?: string;
    // ui only
    progress?: number;
    filePath?: string;
    downloadError?: string;
}

export interface TaskClassValue
{
    classValue: ClassValue;
}

export interface MoonTask
{
    id?: string;
    taskType: MoonTaskType;
    taskNumber: number;
    shareNumber?: number;
    priority: 1 | 2 | 3;
    companyId?: string;
    company?: Company;
    name: string;
    description?: string;
    descriptionStorageId?: string;
    createdBy?: User;
    createdById?: string;
    owner?: User;
    ownerId?: string;
    assigned?: User;
    assignedId?: string;
    timestampUtc?: Date;
    expirationTimestampUtc?: Date;
    lastUpdateTimestampUtc?: Date;
    lastUpdateUser?: User;
    taskInfos?: TaskInfos;
    archived?: boolean;
    subTasks: SubTask[];
    taskDocumentTemplates?: TaskDocumentTemplate[];
    attachments: MoonTaskAttachment[];
    findAndReplace: FindAndReplacePair[];
    moonTask_Audits?: MoonTaskAudit[];
    reviewerAssignments: ReviewerAssignment[];
    documentContents?: DocumentContent[];
    contentGroups?: ContentGroup[];
    myPendingReviewCount?: number;
    isPendingForCurrentUser?: boolean;
    sharedFiles?: ExportFilesIdentifiers[];
    taskProject?: TaskProject;
    taskMessages?: TaskMessage[];
    isTemplate?: boolean;
    documentTypeId?: string;
    documentType?: DocType;
    taskClassValues?: TaskClassValue[];
    nutritionalTable?: NutritionalTable;

    // for UI only
    showSubTasks?: boolean;
    selected?: boolean;
    dirty?: boolean;
    status?: 'loading' | 'loaded';
    isExpiredTask?: boolean;
    // for task list
    highlightOwnerNotification?: boolean;
    // Extension UI Only
    expanded?: boolean;
}
