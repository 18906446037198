export class DocumentVersionMetadata
{
    documentVersionId?: string;
    
    colors: string[];
    fonts: DocumentVersionFonts[];
    hasVisibleOverprint: boolean;
    hasVisibleTransparency: boolean;

    constructor()
    {
        this.colors = [];
        this.fonts = [];
        this.hasVisibleOverprint = false;
        this.hasVisibleTransparency = false;
    }
}

export interface DocumentVersionFonts
{
    fontFace: string;
    fontFamily: string;
    fontFileName: string;
    fontName: string;
}