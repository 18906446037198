import { Injectable, Inject } from '@angular/core';
import { PlatformInfoProvider } from '../_dependencies/platform-info-provider';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';

export interface ExtensionVersion
{
  version: string;
  versiontext: string;
  extensionName: string;
}

@Injectable({
  providedIn: 'root'
})
export class ExtensionService
{
  constructor(@Inject('PlatformInfoProvider') private platformInfoProvider: PlatformInfoProvider,
    private httpClient: HttpClient)
  {}

  async getExtensionVersion(): Promise<ExtensionVersion>
  {
    return new Promise<ExtensionVersion>(async(res, rej) =>
    {
      this.httpClient.get<ExtensionVersion>(`${this.platformInfoProvider.getBackendUrl()}/api/extension/getExtensionVersion`, {})
        .subscribe(response => res(response), error => rej(error));
    });
  }

  downloadExtension(progressCallback: (numberProgress) => void): Promise<any>
  {
    return new Promise<any>(async (resolve, reject) =>
    {
      const progressCallbackInt = progressCallback;
      const downloadReq = new HttpRequest('GET', `${this.platformInfoProvider.getBackendUrl()}/api/extension/downloadExtension`,
        undefined,
        {
          reportProgress: true,
          responseType: 'arraybuffer'
        });
      this.httpClient.request(downloadReq).subscribe(event =>
      {
        if (event.type === HttpEventType.DownloadProgress && progressCallbackInt)
        {
          const percentage = Math.round(100 * event.loaded / event.total);
          progressCallbackInt(percentage);
        }
        if (event.type === HttpEventType.Response)
        {
          const buf = <ArrayBuffer>(event.body);
          console.log(`Download ready, received ${buf.byteLength} bytes`);
          const intArray = new Int8Array(buf);
          const newbuf = new Buffer(<any>intArray, 'base64');
          resolve(newbuf);
        }
      }, error =>
      {
        reject(error && error.message ? error.message : 'error');
      }, () =>
      {
        //
      });
    });
  }
}
