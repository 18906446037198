import { Injectable } from '@angular/core';
import {
  ApplicationInsights,
  IEventTelemetry,
  IExceptionTelemetry,
  IPageViewTelemetry,
  ITraceTelemetry,
  SeverityLevel
} from '@microsoft/applicationinsights-web';
import { Severity } from '../public_api';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService
{

  private appInsights: ApplicationInsights;


  public properties: { [name: string]: string } = {};

  constructor()
  {}

  public async initAppInsights(appInsightsKey?: string): Promise<boolean>
  {
    if (appInsightsKey)
    {
      console.log('LoggingService activating AppInsights: ' + appInsightsKey);

      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: appInsightsKey,
          isCookieUseDisabled: true,
          isStorageUseDisabled: true,
          enableSessionStorageBuffer: true
        }});
      this.appInsights.loadAppInsights();

      this.trackTrace('ApplicationInsights initialised');
      return true;
    }
    else if (this.appInsights !== undefined)
    {
      this.appInsights.flush();
      this.appInsights.emptyQueue();
      this.appInsights = undefined;
      return false;
    }
  }

  public refreshAuthentication(authenticated: string, company: string)
  {
    if (authenticated)
    {
      this.appInsights.setAuthenticatedUserContext(authenticated, company, false);
      this.trackTrace('setAuthenticatedUserContext: ' + authenticated + ', ' + company);
    }
    else
    {
      this.appInsights.clearAuthenticatedUserContext();
      this.trackTrace('clearAuthenticatedUserContext');
    }
  }

  public trackTrace(message: string, severity: Severity = Severity.Verbose)
  {
    if (this.appInsights && severity > Severity.Verbose)
    {
      const telem: ITraceTelemetry =
      {
        message: message,
        properties: this.properties,
        severityLevel: <SeverityLevel><any>severity
      };
      this.appInsights.trackTrace(telem);
    }
  }

  trackEvent(message: string, measurements?: { [name: string]: number }, properties?: { [name: string]: string })
  {
    console.log(message);
    if (this.appInsights)
    {
      let props = this.properties;
      if (properties)
      {
        props = _.clone(props);
        const keys = Object.keys(properties);
        keys.forEach(key => props[key] = properties[key]);
      }
      const telem: IEventTelemetry =
      {
        measurements: measurements,
        name: message,
        properties: props
      };
      this.appInsights.trackEvent(telem);
    }
  }

  startTrackEvent(event: string)
  {
    console.log(`startTrackEvent: ${event}`);
    if (this.appInsights)
    {
      this.appInsights.startTrackEvent(event);
    }
  }

  stopTrackEvent(event: string, measurements?: { [name: string]: number }, properties?: { [name: string]: string })
  {
    console.log(`stopTrackEvent: ${event}`);
    let props = this.properties;
    if (properties)
    {
      props = _.clone(props);
      const keys = Object.keys(properties);
      keys.forEach(key => props[key] = properties[key]);
    }
    if (this.appInsights)
    {
      this.appInsights.stopTrackEvent(event, props, measurements);
    }
  }

  trackPageView(pageName: string, measurements?: { [name: string]: number }, isLoggedIn?: boolean)
  {
    if (this.appInsights)
    {
      const telem: IPageViewTelemetry =
      {
        name: pageName,
        isLoggedIn: isLoggedIn,
        measurements: measurements,
        properties: this.properties
      };
      this.appInsights.trackPageView(telem);
    }
    else
    {
      console.log(`trackPageView(${pageName})`);
    }
  }

  logException(error: Error, measurements?: { [name: string]: number }, properties?: { [name: string]: string })
  {
    console.log(error);
    if (this.appInsights)
    {
      const telem: IExceptionTelemetry =
      {
        error: error,
        measurements: measurements,
        properties: this.properties
      };
      this.appInsights.trackException(telem, properties);
      this.appInsights.flush(); // in case of exceptions it's more urgent
    }
  }
}
