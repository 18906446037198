import { FieldValue } from './FieldValue';
import { DocumentTag } from './DocumentTag';
import { MoonDeskDocument } from './Document';
import { RuleResult } from '../rules/RuleResult';
import { User } from '../user/User';
import { ImageCompare } from '../imageCompare/ImageCompare';
import { DocumentTaskHistory } from '../tasks/DocumentTaskHistory';
import { Product } from '../products/Product';
import { DocumentVersionMetadata } from './DocumentVersionMetadata';
import { MoonTask } from '../tasks/MoonTask';
import { DocumentVersionAIAnalysis } from './DocumentVersionAIAnalysis';

export interface DocumentVersion
{
    id?: string;
    documentId?: string;
    document?: MoonDeskDocument;
    creator?: string;
    timestampUtc?: Date;
    fieldValues: FieldValue[];
    documentTags: DocumentTag[];
    versionNumber?: number;
    minorVersionNumber?: number;
    /** .ai, .jpg or whatever ...  */
    fileType: string;

    checksum?: string;
    /**
     * Used to save the original document checksum (before it gets manipulated by moon hidden layers).
     * useful to check duplicates on mass imports
     */
    originalFileChecksum?: string;

    fullText?: string;
    imageUrl?: any;
    textWasAnalyzed?: boolean;
    ruleResults?: RuleResult[];
    status?: 'Preapproved' | 'Draft' | 'Approved';
    approvedRejectedBy?: User;
    approvedRejectedTimestampUtc?: Date;
    approvedInTask?: MoonTask;
    classValueStringSnapshot?: string;

    exportFiles?: ExportFilesIdentifiers[];
    childVersionIds?: string[];
    approvedRejectedById?: string;

    uploadedFromWeb?: boolean;

    imageCompares?: ImageCompare[];

    metadata: DocumentVersionMetadata;

    aiAnalyses?: DocumentVersionAIAnalysis[];

    /**
     * Only for image library elements
     */
    documentName?: string;
    documentNumber?: string;

    /**
     * This is used to return full text of illustrator with marks on Bold characters
     */
    fullTextCharacters?: any;
    /**
     * If an .ai document was created from a backup, or based on another file (duplicate, not implemented yet in 1.17),
     * The Illustrator extension has to know this when opening, to fix the links and also the moondesk metadata info
     */
    restoreInformation?: RestoreInformation;

    storageUsage?: number;

    isLatestVersion?: boolean;

    // UI ONLY
    uniqueExportName?: string;
    docTaskHistories?: DocumentTaskHistory[];
    // UI Task chat
    panelExpanded?: boolean;
    productId?: string;
    product?: Product;
}


export interface RestoreInformation
{
    originalCompanyId: string;
    originalVersionId: string;
    /**
     * [0] id of the original documentversion of the lib element
     * [1] id of the new documentversion of the lib element
     */
    linkReplacements?: LinkReplacement[];
}

export interface LinkReplacement
{
    item1: string;
    item2: string;
}

export enum ExportFilesIdentifiers
{
    Export_Jpg = 'document_export.jpg',
    Export_OutlinePdf = 'document_outline.pdf',
    Export_EditablePdf = 'document_editable.pdf',
    Export_OutlineAi = 'document_outline.ai',
    Export_Ai = 'document.ai',
    Export_PackageZip = 'document_package.zip',
    Export_OutlineZip = 'document_outline.zip',
    FullImagePng = 'fullImage.png',
    PreviewPdf = 'preview.pdf',
    /**
     * Used for WEB uploads
     * This value is used for compressed files (zip, rar, 7z, tar, etc.) uploaded by the user.
     * The appropriate file extension MUST be added before uploading.
     */
    Export_CustomPackage = 'custom_package'
}
