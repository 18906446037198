import { Injectable } from '@angular/core';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class TextsFormattingService {

  constructor()
  {
    // empty
  }

  removeHtml(html: string): string
  {
    const div = document.createElement('div');
    div.innerHTML = html;
    const plainText = div.innerText;
    // replace nbsp to normal spaces (unicode 32)
    const nbspRegex = new RegExp(String.fromCharCode(160), 'g');
    return plainText.replace(nbspRegex, ' ');
  }

  cleanHtml(html: string, keepLinesBreak?: boolean, keepBold?: boolean, keepAttributes?: boolean, keepNbsp?: boolean): string
  {
    let cleanedHtml = html;
    // remove "plain text" new lines
    if (!keepNbsp)
    {
      cleanedHtml = this.removeLineBreaks(cleanedHtml);
    }

    if (!keepAttributes)
    {
      // remove tags attributes
      const tagsAttributesRegex = /<\s*([a-z][a-z0-9]*)\s.*?>/gi;
      cleanedHtml = cleanedHtml.replace(tagsAttributesRegex, '<$1>');
    }

    // delete all tags but keep the selected ones
    const keepTags: string[] = [];
    let regExpText: string = '';
    if (keepLinesBreak)
    {
      keepTags.push('p');
      keepTags.push('\\/p');
    }
    else
    {
      cleanedHtml = cleanedHtml.replace(/<p[^>]*>/gm, ' ');
      cleanedHtml = cleanedHtml.replace(/<br>/gm, ' ');
    }
    if (keepBold)
    {
      keepTags.push('strong');
      keepTags.push('\\/strong');
      keepTags.push('b');
      keepTags.push('\\/b');
    }
    if (keepTags && keepTags.length > 0)
    {
      const tags = keepTags.join('|');
      const keepTagsString = `(${tags})`;
      regExpText = `<(?!(${keepTagsString})\s*\/?)[^>]+>`;
    }
    else
    {
      regExpText = `<[^>]*>`;
    }
    const regExp = new RegExp(regExpText, 'gm');
    cleanedHtml = cleanedHtml.replace(regExp, '');
    cleanedHtml = cleanedHtml.replace(/\s\s+/g, ' ');

    return cleanedHtml;
  }

  /**
   * Transforms html to string
   * @param formatLinesBreak change \<p> to /n
   * @param formatBoldOnly change \<b> and/or \<strong> to ¶someChar§ and return only the bold chars
   * @param keepHtml keep tags (or not) that have not been formatted by the selected parameters
   * @returns formatted string, can by plain text or html based on the params
   */
  formatHtml(html: string, formatLinesBreak?: boolean, formatBoldOnly?: boolean, keepHtml?: boolean): string
  {
    let result: string = html;

    result = this.removeLineBreaks(result);

    if (formatLinesBreak)
    {
      result = result.replace(/<p[^>]*>/gm, '\n');
      result = result.replace(/<br>/gm, '\n');
    }
    if (formatBoldOnly)
    {
      const div = document.createElement('div');
      div.innerHTML = result;
      const boldElements = div.querySelectorAll('b,strong');
      const boldIterate = Array.prototype.slice.call(boldElements);
      let newInnerText = '';
      if (boldIterate && boldIterate.length > 0)
      {
        for (let i = 0 ; i < boldIterate.length ; i++)
        {
          const boldElement = boldIterate[i];
          let text = '';
          // ALT + 244   AND ALT + 245
          // boldElement.innerText = boldElement.innerText.trim();
          for (let j = 0 ; j < boldElement.innerText.length ; j++)
          {
            if (boldElement.innerText.trim().length > 0)
            {
              text = text + '¶' + boldElement.innerText[j] + '§';
            }
          }
          newInnerText = newInnerText + text;
        }
      }
      result = newInnerText;
    }
    if (!keepHtml)
    {
      result = this.removeHtml(result);
    }
    return result;
  }

  /**
   * Replace all <b></b> and <strong></strong> with ¶§ (for every char between the tags)
   * @param allowExtraBold allow characters that are not in bold (not between <b> tags) to be bold or not
   * @returns a one line moonBoldString without HTML or spaces (with bold and normal characters)
   */
  getMoonBoldString(richText: string, allowExtraBold: boolean): string
  {
    let moonBoldString = '';
    let cleanString = '';

    // 1. Remove all but the bold html tags and replace all strong tags with b tags
    cleanString = this.cleanHtml(richText, false, true, false)
      .replace(/<strong>/gmi, '<b>')
      .replace(/<\/strong>/gmi, '<\/b>');

    // 1.1. Bugfix (https://ciclosoftware.visualstudio.com/YGContras/_sprints/taskboard/Team%20MoonDesk/YGContras/Sprint%2081?workitem=12790)
    cleanString = cleanString.replace(/&lt;/gmi, '<').replace(/&gt;/gmi, '>');

    // 1.2. Bugfix (https://ciclosoftware.visualstudio.com/YGContras/_sprints/taskboard/Team%20MoonDesk/YGContras/Sprint%2091?workitem=13110)
    cleanString = cleanString.replace(/&amp;/gmi, '&');

    // 2. Replace all <b></b> with ¶§ (for every char between the tags)
    let addBoldMarks: boolean = false;

    for (let i = 0; i < cleanString.length; i++)
    {
      const currentChar = cleanString[i];

      if (cleanString.length >= i + 3)
      {
        const openBoldTagChecker = cleanString.substring(i, i + 3);
        if (openBoldTagChecker === '<b>')
        {
          addBoldMarks = true;
          i = i + 2;
          continue;
        }
      }

      if (cleanString.length >= i + 4)
      {
        const closeBoldTagChecker = cleanString.substring(i, i + 4);
        if (closeBoldTagChecker === '<\/b>')
        {
          addBoldMarks = false;
          i = i + 3;
          continue;
        }
      }

      if (currentChar !== ' ')
      {
        const cleanedCurrentChar = currentChar.replace(/[-[\]{}()*+?.,\\^$#\s]/g, '\\$&');
        if (addBoldMarks)
        {
          moonBoldString = moonBoldString + `¶${cleanedCurrentChar}§`;
        }
        else
        {
          if (allowExtraBold && cleanedCurrentChar != '|')
          {
            moonBoldString = moonBoldString + `[¶|§]?${cleanedCurrentChar}[¶|§]?`;
          }
          else
          {
            moonBoldString = moonBoldString + cleanedCurrentChar;
          }
        }
      }
    }

    // 3. Remove all whitespaces, linebreaks, escape special chars and replace arabic numbers
    let result = this.formatString(moonBoldString, true, true, false);
    result = this.replaceArabicNumbers(result);

    // 4. Replace all || to | (Regex use one | for or)
    result = result.replace(/\|\|/gm, '|');
    return result;
  }

  formatString(
    dirtyText: string,
    removeLineBreaks?: boolean,
    removeWhiteSpaces?: boolean,
    escapeSpecialChars?: boolean,
    removeEmptyBoldMarks?: boolean): string
  {
    let cleanText: string = dirtyText;
    if (removeLineBreaks)
    {
      cleanText = this.removeLineBreaks(cleanText);
    }
    if (removeWhiteSpaces)
    {
      cleanText = cleanText.replace(/\s/g, '');
    }
    if (escapeSpecialChars)
    {
      cleanText = cleanText.replace(/[-[\]{}()*+?.,'\/\\^$#\|ʻ‘’]/g, '\\$&');
    }
    if (removeEmptyBoldMarks)
    {
      cleanText = cleanText.replace(/¶§/g, '');
    }
    // cleanText = cleanText.replace(/[ʻ‘’]/g, '\'');

    return cleanText;
  }

  removeLineBreaks(text: string): string
  {
    let result: string = text;
    result = result.replace(/(?:\r\n|\r|\n)/g, ' ');
    result = result.replace(/&nbsp;/g, ' ');
    const nbspRegex = new RegExp(String.fromCharCode(160), 'g');
    result = result.replace(nbspRegex, ' ');
    result = result.replace(/\s\s+/g, ' ');
    return result;
  }

  replaceArabicNumbers(str: string): string
  {
    const arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
    for (let i = 0; i < 10; i++)
    {
      str = str.replace(arabicNumbers[i], `${i}`);
    }
    return str;
  }

  removeHTMLcomments(str: string): string
  {
    let result: string = str;
    const normalCommentRegex = new RegExp('<!--[^>]*-->', 'gs');
    result = result.replace(normalCommentRegex, '');
    const encodedCommentRegex = new RegExp('\&lt\;!--(.*)--\&gt\;', 'gs');
    result = result.replace(encodedCommentRegex, '');
    return result;
  }

  removeMoonWildCards(dirtyText: string): string
  {
    let cleanText = dirtyText.replace(/wContains:/g, '');
    cleanText = cleanText.replace(/wStarts:/g, '');
    cleanText = cleanText.replace(/wEnds:/g, '');
    return cleanText;
  }
}
