import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BackendInfo } from '../_models/api/BackendInfo';
import { PlatformInfoProvider } from '../_dependencies/platform-info-provider';
import { fromEvent } from 'rxjs';
import { TranslationService } from './translation.service';
import { AppInsightsService } from './app-insights.service';
import { Identity } from '../_models/user/Identity';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService
{
  constructor(
    @Inject('PlatformInfoProvider') private platformInfoProvider: PlatformInfoProvider,
    private translationService: TranslationService,
    public httpClient: HttpClient,
    private appInsightsService: AppInsightsService)
  {
    fromEvent(window, 'online').subscribe(e => this.setOnline(true));
    fromEvent(window, 'offline').subscribe(e => this.setOnline(false));
    this.setOnline(navigator.onLine);
  }

  // todo improve offline handling before using http
  private online: boolean;

  async defaultConnectionTest(): Promise<BackendInfo>
  {
    return this.connectionTest(this.platformInfoProvider.getBackendUrl());
  }

  async connectionTest(url: string): Promise<BackendInfo>
  {
    const fi = await this.platformInfoProvider.getFrontendInfo();

    return new Promise<BackendInfo>((resolve, reject) =>
    {
      this.httpClient.post<BackendInfo>(`${url}/api/test/extensionconnectiontest`, fi).subscribe(
        response =>
        {
          resolve(response);
        },
        error =>
        {
          this.handleReject(error, reject);
        }
      );
    });
  }
  private setOnline(online: boolean)
  {
    console.log(`AuthService ${online ? 'ONLINE' : 'OFFLINE'}`);
    this.online = online;
  }

  isOnline(): boolean
  {
    return this.online;
  }

  handleReject(error, reject, identity: Identity = null, requestBody: string = null) // , requestParams: string
  {
    let err: string;
    // Offline is offline
    if (!this.online)
    {
      err = 'NoConnection';
    }
    // 400 means bad request and can contain a moondesk error
    else if (error.status === 400)
    {
      if (error.error)
      {
        if (error.error.byteLength && error.error.byteLength)
        {
          const enc = new TextDecoder('utf-8');
          err = enc.decode(error.error);
        }
        else
        {
          err = error.error;
        }
      }
    }
    // We use the http response code as errorCode
    else
    {
      err = error.status;
    }
    if (err === undefined)
    {
      err = 'UnexpectedError';
    }

    try
    {
      /**
       * TODO - Define optimal maxRequestBodyLength value
       */
      const maxRequestBodyLength = 10000;
      const exceptionProperties: {[name: string]: string} =
      {
        username: identity?.user?.username,
        reviewer: identity?.reviewer?.email,
        company: identity?.company?.name,
        requestBody: requestBody?.slice(0, maxRequestBodyLength),
      };
      this.appInsightsService.logException(error, null, exceptionProperties);
    }
    catch (e)
    {
      console.log('Error logging error to AppInsight', e);
    }

    error.error = this.translateServerError(err);
    reject(error);
  }

  translateServerError(err: string): string
  {
    const translateKey = `lid.srv.${err}`;
    let translation = this.translationService.getTranslation(translateKey);
    if (translation === translateKey)
    {
      translation = err;
    }
    return translation;
  }
}
