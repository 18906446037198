/* eslint-disable @typescript-eslint/naming-convention */
import { RuleClassValue, RuleDocumentType } from './RuleFilters';
import { User } from '../user/User';

export enum RuleIDs
{
  TEXT_CONTENT = 'TEXT_CONTENT',
  LIBRARY_CONTENT = 'LIBRARY_CONTENT',
  DUPLICATE_DOCUMENTS = 'DUPLICATE_DOCUMENTS',
  DOUBLE_SPACES = 'DOUBLE_SPACES',
  LOCAL_LINKS = 'LOCAL_LINKS',
  OVERFLOWED_TEXTITEM = 'OVERFLOWED_TEXTITEM',
  MISSING_FONTS = 'MISSING_FONTS',
  MOCK = 'MOCK',
  USER_CHECKED = 'USER_CHECKED'
}

export interface RuleConfiguration
{
  id?: string;
  companyId?: string;
  ruleID?: RuleIDs;
  ruleOptions?: string;
  description?: string;
  descriptionStorageId?: string;
  ruleNumber?: number;

  ruleClassValues?: RuleClassValue[];
  ruleDocumentTypes?: RuleDocumentType[];

  isFromTask?: boolean;

  archived?: boolean;
  lastModificationTimestampUtc?: Date;
  ruleTags?: RuleTag [];
  creationTimestampUtc?: Date;

  createdById?: string;
  createdBy?: User;

  lastUpdateUserId?: string;
  lastUpdateUser?: User;

  scopeConditonString?: string;
  contentConditionString?: string;

  // UI ONLY
  latestChangeString?: string;
  documentContentId?: string;
}

export interface RuleTag
{
  ruleId?: string;
  value?: string;
}

export interface ContentTextRuleConfiguration
{
  findCondition: 'exist' | 'notExist';
  findText?: string;
  // findTextCaseSensitive: boolean;
  checkCondition: 'exist' | 'notExist';
  checkText: string;
  checkTextCaseSensitive?: boolean;
  checkTextBasedOnMaxSize?: boolean;
  /**
   * MINIMUM textSize will be controlled only if maxTextSize is null or 0
   * Keep as separate values so that both can be controlled in the future (if necessary).
   * This value will be compared with the smallest character of the document
   */
  textSize?: number;
  /**
   * MAXIMUM textSize has higher priority than textSize
   * Keep as separate values so that both can be controlled in the future (if necessary).
   * This value will be compared with the biggest character of the document
   */
  maximumTextSize?: number;
  ruleDescription: string;
  richTextDescription?: string; // Needed to show bolds char in the results UI
  richCheckText?: string; // Needed to show bolds char in the results UI

  /* Dirty check text will be used to generate bold marks on string */
  /**
   * @deprecated richCheckText is enough to check the rule
   */
  dirtyCheckText?: string;
}

export interface ContentLibraryRuleConfiguration
{
  docVersions: LibRuleDocVersion[];
  logicalOperator: 'and' | 'or';
  ruleDescription: string;
}

export interface LibRuleDocVersion
{
  versionId: string;
  docName: string;
  logicalOperatorSize: '<' | '>' | '=';
  width: number;
  height: number;
}

export interface UserCheckedRuleConfiguration
{
  ruleDescription: string;
}
