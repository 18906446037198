<div class="library">
  <div class="header">
    <div class="title">
      <span>RESOURCES</span>
    </div>
    <div class="refresh-button" *ngIf="!busy" (click)="searchLibrary()">
      <mat-icon class="refresh-icon">refresh</mat-icon>
      <span class="text">Refresh</span>
    </div>
    <div *ngIf="busy">
      <mat-progress-spinner class="spinner" diameter="16" color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>

  <div class="body">
    <div class="resource-type-selector">
      <mat-form-field class="filter-field" appearance="outline">
        <mat-select disableOptionCentering [(value)]='libraryElementType' [disabled]="busy" (selectionChange)='searchLibrary()'>
          <mat-option [value]="'all'">All the resources</mat-option>
          <mat-option [value]="'image'">Images</mat-option>
          <mat-option [value]="'font'">Fonts</mat-option>
          <mat-option [value]="'currentDoc'">Current document</mat-option>
          <mat-option [value]="'archived'">Archived</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="resource-filter">
      <div class="search-box">
        <span class="search-icon">
          <mat-icon class="notranslate">search</mat-icon>
        </span>
        <input type="text" [placeholder]="'Search by name'" [disabled]="busy" (keyup.enter)="addSearchTag();" [formControl]="filterText">
      </div>
      <div class="search-tags">
        <div class="tag" *ngIf="searchText"> <!-- *ngFor="let tag of searchTags" -->
          <span class="tag-text" [matTooltip]="searchText">{{searchText}}</span>
          <span class="remove-btn" (click)="removeSearchTag()">
            <mat-icon>clear</mat-icon>
          </span>
        </div>
      </div>
      <div class="info">
        <span class="results-count">{{totalResults}} results</span>
        <div class="view-selector">
          <span class="view-button" [ngClass]="{'selected': viewMode === 'grid'}" (click)="viewMode = 'grid'">
            <mat-icon>grid_view</mat-icon>
          </span>
          <span class="view-button" [ngClass]="{'selected': viewMode === 'list'}" (click)="viewMode = 'list'">
            <mat-icon>format_list_bulleted</mat-icon>
          </span>
        </div>
      </div>
    </div>

    <div class="resource-list" *ngIf="libraryElements">
      <div [ngClass]="{'list-view': viewMode === 'list', 'grid-view': viewMode === 'grid'}" [@listAnimation]="libraryElements.length">
        <ng-container *ngFor="let libElement of libraryElements">
          <app-library-card [libraryElement]="libElement" [viewMode]="viewMode" (libElementChanged)="searchLibrary()"></app-library-card>
        </ng-container>
      </div>
      <div class="center">
        <div class="load-more-btn" *ngIf="currentPage < totalPages && !busy">
          <button class="md-button" (click)="loadMoreLibraryElements()">Load More</button>
        </div>
        <div class="busy" *ngIf="busy">
          <mat-progress-spinner class="spinner" diameter="38" color="accent" mode="indeterminate"></mat-progress-spinner>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">
    <span class="upload-button" for="file" (click)="addFiles()">
      <mat-icon class="refresh-icon">cloud_upload</mat-icon>
      <span class="text">Upload</span>
    </span>
    <input id="file" hidden="true"
      #fileInput
      type="file"
      (change)="addDocument(fileInput.files)">
  </div>
</div>