import { Injectable } from '@angular/core';

declare var dynamsoft: any;

@Injectable({
    providedIn: 'root'
})
export class DynamsoftBarcodeService
{
    private barcodeReader: any;

    constructor()
    {
        // Dynamsoft
    }

    initDynamsoft(): Promise<void>
    {
        return new Promise<void>((res, rej) =>
        {
            if (this.barcodeReader)
            {
                res();
            }
            const head = document.getElementsByTagName('head')[0];

            let script1 = <HTMLScriptElement>document.getElementById('dynamsoft1');
            if (script1)
            {
                document.removeChild(script1);
            }
            script1 = document.createElement('script');
            script1.id = 'dynamsoft1';
            script1.type = 'text/javascript';
            script1.text = 'var dynamsoft = self.dynamsoft || {}';
            head.appendChild(script1);
            // dynamsoftModule.onRuntimeInitialized = function () {
            //     this.barcodeReader =
            // new dynamsoftModule.BarcodeReaderWasm(
            //    "t0068NQAAABlXt6Ywye+FFGmWAPvlu4RaWnXn2WwBr/009zO1rPhKSX+d+R8GyYg1i3GUX0OQj0jsxItZ6DiE6GFk7upN43g=");
            //   };
            // const env = this;
            dynamsoft.dbrEnv = dynamsoft.dbrEnv || {};
            dynamsoft.dbrEnv.resourcesPath = 'https://demo.dynamsoft.com/dbr_wasm/js';
            // https://www.dynamsoft.com/CustomerPortal/Portal/TrialLicense.aspx
            dynamsoft.dbrEnv.licenseKey =
                't0068NQAAABlXt6Ywye+FFGmWAPvlu4RaWnXn2WwBr/009zO1rPhKSX+d+R8GyYg1i3GUX0OQj0jsxItZ6DiE6GFk7upN43g=';
            dynamsoft.dbrEnv.bUseWorker = true;
            dynamsoft.dbrEnv.onAutoLoadWasmSuccess = () =>
            {
                this.barcodeReader = new dynamsoft.BarcodeReader();
                if (this.barcodeReader)
                {
                    res();
                }
                else
                {
                    rej('BarcodeReader is null');
                }

            };
            dynamsoft.dbrEnv.onAutoLoadWasmError = (ex) =>
            {
                rej(ex);
            };
            let script2 = <HTMLScriptElement>document.getElementById('dynamsoft2');
            if (script2)
            {
                document.removeChild(script2);
            }
            script2 = document.createElement('script');
            script2.id = 'dynamsoft2';
            script2.type = 'text/javascript';
            script2.src = 'https://demo.dynamsoft.com/dbr_wasm/js/dbr-6.4.1.3.min.js';
            head.appendChild(script2);
        });
    }

    readBarcodes(base64String: string): Promise<string[]>
    {
        return new Promise<string[]>(async(res, rej) =>
        {
            try
            {
                await this.initDynamsoft();
                this.barcodeReader
                    .decodeBase64String(base64String)
                    .then(function(results)
                    {
                        const txts = [];
                        for (let i = 0; i < results.length; ++i)
                        {
                            txts.push(results[i].BarcodeText);
                        }
                        res(txts);
                    })
                    .catch((ex) =>
                    {
                        rej(ex);
                    });
            }
            catch (err)
            {
                this.barcodeReader = null;
                rej(err);
            }
        });
    }
}
