import { Injectable, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'underscore';
import { AuthService, Progress } from './auth.service';
import { CompanyBackup } from '../_models/backup_restore/CompanyBackup';
import { CompanyRestore } from '../_models/backup_restore/CompanyRestore';
import { PlatformInfoProvider } from '../_dependencies/platform-info-provider';

@Injectable({
  providedIn: 'root'
})
export class BackupRestoreService {

  constructor(private authService: AuthService, private sanitizer: DomSanitizer,
    @Inject('PlatformInfoProvider') private platformInfoProvider: PlatformInfoProvider)
    { }

  /**
   * To request a new backup
   */
  async startBackup(companyId: string, includeDocuments: boolean, includeTasks: boolean, includeRules: boolean): Promise<CompanyBackup>
  {
    const params: [string, string][] =
    [
      ['companyId', companyId],
      ['includeDocuments', `${includeDocuments}`],
      ['includeTasks', `${includeTasks}`],
      ['includeRules', `${includeRules}`],
    ];
    const result = await this.authService.authGet<CompanyBackup>('/api/backuprestore/startBackup', params);
    this.fixCompanyBackupTimestamps([result]);
    this.fixDownloadUrls([result]);
    return result;
  }

  /**
   * To delete (or cancel) an existing backup
   */
  async deleteBackup(backupId: string): Promise<void>
  {
    const params: [string, string][] = [['backupId', backupId]];
    await this.authService.authGet<void>('/api/backuprestore/deleteBackup', params);
  }

  /**
   * Get the list of existing backups (requested, in progress, error, uploaded and done).
   * If a companyId is provided, all the corresponding backups are shown
   * If no companyId is provided, it returns all backups a user created (requested or uploaded)
   */
  async getBackups(companyId?: string): Promise<CompanyBackup[]>
  {
    const params: [string, string][] = [];
    if (companyId)
    {
      params.push(['companyId', companyId]);
    }
    const result = await this.authService.authGet<CompanyBackup[]>('/api/backuprestore/getBackups', params);
    this.fixCompanyBackupTimestamps(result);
    return _.sortBy(result, b => b.timestampUtc).reverse();
  }

  /**
   * To request a new company based on a backup
   */
  async requestCompanyFromBackup(companyName: string,
    backupId: string,
    includeDocuments: boolean,
    includeTasks: boolean,
    includeRules: boolean): Promise<CompanyRestore>
  {
    const params: [string, string][] =
    [
      ['companyName', companyName],
      ['backupId', backupId],
      ['includeDocuments', `${includeDocuments}`],
      ['includeTasks', `${includeTasks}`],
      ['includeRules', `${includeRules}`]
    ];
    const result = await this.authService.authGet<CompanyRestore>('/api/backuprestore/requestCompanyFromBackup', params);
    this.fixCompanyRestoreTimestamps([result]);
    return result;
  }

  /**
   * To upload an existing backup file (e.g. from other cloud). Can be used for restore afterwards.
   */
  async uploadBackup(files: File[], progressCallback?: (progress: Progress) => void): Promise<CompanyBackup[]>
  {
    const formData = new FormData();
    if (files && files.length > 0)
    {
      files.forEach(f => formData.append('file', f, f.name));
    }
    const result = await this.authService.longPost<CompanyBackup[]>(`/api/backuprestore/uploadBackupForm`,
      formData, perc => progressCallback(perc));
    return result;
  }

  /**
   * Get the restores (CreateCompanyFromBackup) the current user performed (can be in progress, done or in error)
   */
  async getCompanyRestores(): Promise<CompanyRestore[]>
  {
    const result = await this.authService.authGet<CompanyRestore[]>('/api/backuprestore/getCompanyRestores');
    this.fixCompanyRestoreTimestamps(result);
    return _.sortBy(result, r => r.timestampUtc).reverse();
  }

  /**
   * To delete a restore that is requested or done. InProgress can't be deleted
   */
  async deleteCompanyRestore(restoreId: string): Promise<void>
  {
    const params: [string, string][] = [['restoreId', restoreId]];
    await this.authService.authGet<void>('/api/backuprestore/deleteCompanyRestore', params);
  }

  private fixCompanyBackupTimestamps(objs: CompanyBackup[])
  {
    objs.forEach(obj => obj.timestampUtc = new Date(obj.timestampUtc));
  }

  private fixDownloadUrls(objs: CompanyBackup[])
  {
    objs.forEach(bu =>
    {
      if (bu.downloadUrl)
      {
        bu.downloadUrl = <any>this.sanitizer.bypassSecurityTrustResourceUrl(bu.downloadUrl);
      }
    });
  }

  private fixCompanyRestoreTimestamps(objs: CompanyRestore[])
  {
    objs.forEach(obj => obj.timestampUtc = new Date(obj.timestampUtc));
  }
}
