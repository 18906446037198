import { MoonTask } from '../tasks/MoonTask';
import { SubTaskMessage } from '../tasks/SubTaskMessage';
import { TaskMessage } from '../tasks/TaskMessage';

export interface TaskNotification
{
  // action is more like an event, but we don't change the name for compatibility
  action: string;
  task: MoonTask;
  additionalInfo: string;
  subTaskMessage?: SubTaskMessage;
  taskMessage?: TaskMessage;
}

export enum TaskNotificationEvent
{
  TaskShared = 1,
  TaskDone = 2,
  TaskAssigned = 3,
  TaskOwnership = 4,
  SubTaskReject = 5,
  SubTaskApprove = 6,
  SubTaskReopen = 7,
  TaskToDo = 8,
  TaskInReview = 9,
  TaskComment = 10,
  TaskCreated = 11,
  TaskUpdated = 12,
  TaskArchived = 13,
  TaskRecovered = 14,
  TaskInOwnerReview = 15
}

export enum NotifiableUsers
{
  Owner,
  Creator,
  Assigned,
  Reviewers
}
