import { Company } from '../configuration/Company';
import { ClassSelection } from '../document/DocumentFilter';
import { UserConfig } from './UserConfig';

export interface User
{
  id?: string;
  pending?: boolean;
  blocked?: boolean;
  verificationToken?: string;

  username?: string;
  password?: string;
  oldPassword?: string;
  repeatPassword?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  language?: 'en' | 'es' | 'pt';

  token?: string;
  isSuperUser?: boolean;
  isBetaUser?: boolean;
  /**
   * Users with less than a week (Value calculated in backend)
   */
  isNewUser?: boolean;

  /**
   * Pure UI attribute to reduce backend calls
   * in AuthGuard
   */
  isDemoUser?: boolean;

  timestampUtc?: Date;

  userConfig?: UserConfig;

  fullname?: string;
}

export interface UserAssignment
{
  userId?: string;
  user: User;

  /**
   * When a new user was added/invited by mail, this is the
   * email until he/she finishes the registration
   */
  pendingEmail?: string;

  companyId?: string;
  company: Company;
  role: CompanyRole;
  /**
   * Restrict user access to specific documents
   * This filter is always applied in their searches
   */
  forcedSelection?: ForcedSelection;
  assignmentPermissions?: AssignmentPermissions;
}

export enum CompanyRole
{
  none = 0,
  stakeholder = 1,
  user = 2,
  admin = 3
}

// It must be equal to Backend.Data\Model\Users\UserAssignment.UserAction
export enum UserAction
{
  // Add all configurable permissions here (search for this string to see all the places that should be modified)
  adminClassValues = 'adminClassValues',
  approveDocuments = 'approveDocuments',
  preapproveDocuments = 'preapproveDocuments',
  deleteDocuments = 'deleteDocuments',
  adminRules = 'adminRules',
  uploadDocumentsFromWeb = 'uploadDocumentsFromWeb',
  uploadDocumentsFromExt = 'uploadDocumentsFromExt',
  reclassifyDocuments = 'reclassifyDocuments',
  adminProducts = 'adminProducts',
  viewAllTasks = 'viewAllTasks',
  createTasks = 'createTasks',
  updateTasksIfAssigned = 'updateTasksIfAssigned'
}

export interface ForcedSelection
{
  forcedClassSelections?: ClassSelection[];
  forcedDocTypeIdsSelections?: string[];
  forcedVersionStates?: ['Draft' | 'Rejected' | 'Approved'];
}

export class AssignmentPermissions
{
  // Add all configurable permissions here (search for this string to see all the places that should be modified)
  // Documents
  deleteDocuments?: 'Allow' | 'Deny' | 'None' = 'None';
  uploadDocumentsFromWeb?: 'Allow' | 'Deny' | 'None' = 'None';
  uploadDocumentsFromExt?: 'Allow' | 'Deny' | 'None' = 'None';
  approveDocuments?: 'Allow' | 'Deny' | 'None' = 'None';
  preapproveDocuments?: 'Allow' | 'Deny' | 'None' = 'None';
  reclassifyDocuments?: 'Allow' | 'Deny' | 'None' = 'None';
  // Admin
  adminClassValues?: 'Allow' | 'Deny' | 'None' = 'None';
  adminRules?: 'Allow' | 'Deny' | 'None' = 'None';
  adminProducts?: 'Allow' | 'Deny' | 'None' = 'None';
  // Tasks
  viewAllTasks?: 'Allow' | 'Deny' | 'None' = 'Allow';
  createTasks?: 'Allow' | 'Deny' | 'None' = 'Allow';
  updateTasksIfAssigned?: 'Allow' | 'Deny' | 'None' = 'None';
}
