import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { WorkManagerService } from '../work-manager/work-manager.service';
import { CurrentDocumentService } from '../current-document-manager/current-document.service';
import {
  MoonDeskDocument,
  MoonTask,
  TasksService,
  FeedbackService,
  EventHubService,
  DocumentVersion,
  NotificationHubService,
  PushMessage,
  AuthService,
  Identity,
  CompanyRole,
  PermissionsService,
  UserAction,
  TaskDescriptionPopupData} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import * as _ from 'underscore';
import { FilesystemService } from '../../../services/filesystem.service';
import { AddDocToTaskDialogComponent } from '../../_shared/add-doc-to-task-dialog/add-doc-to-task-dialog.component';
import { SaveDocumentService } from '../save-document/save-document.service';
import { IllustratorService } from '../../../services/illustrator.service';
import { ClassSelectorDialogComponent } from '../../class-selector-dialog/class-selector-dialog.component';
import { DocumentStatusPopupComponent } from '../../_shared/document-status-popup/document-status-popup.component';
import { Subscription } from 'rxjs';
import { RuleCreateComponent } from '../../rules/rule-create/rule-create.component';
import {Clipboard} from '@angular/cdk/clipboard';
import { RuleHelperService } from '../../../services/rule-helper.service';

@Component({
  selector: 'work-header',
  templateUrl: './work-header.component.html',
  styleUrls: ['./work-header.component.scss']
})
export class WorkHeaderComponent implements OnInit, OnDestroy {

  currentDocument: MoonDeskDocument;
  currentTask: MoonTask;

  docFromTask: boolean;
  subTaskMessagesCount: number;
  saving: boolean;

  busy: boolean;

  identity: Identity;
  // DialogRef is necessary for the case in which a user closes the current doc or changes it
  // from Illustrator with a popup open and we need to close that popup
  dialogRef: MatDialogRef<any, any>;

  subscriptions: Subscription[] = [];

  canApprove: boolean;
  canReclassify: boolean = false;

  @Input() mode: 'Edit' | 'Import';
  @Input() keepTags: boolean;
  @Output() keepTagsChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private workManager: WorkManagerService,
              private currentDocMgr: CurrentDocumentService,
              private dialog: MatDialog,
              public taskService: TasksService,
              private feedbackService: FeedbackService,
              private eventHubService: EventHubService,
              private fileService: FilesystemService,
              private ruleHelperService: RuleHelperService,
              private saveDocumentService: SaveDocumentService,
              private illService: IllustratorService,
              private notificationService: NotificationHubService,
              private authService: AuthService,
              private clipboard: Clipboard,
              private permissionsService: PermissionsService)
  {
    this.subscriptions.push(this.workManager.currentTaskChange.subscribe(t => this.taskChange(t)));
    this.subscriptions.push(this.currentDocMgr.documentChange.subscribe(() => this.docChange()));
    this.subscriptions.push(this.notificationService.taskMessagePushed.subscribe((msg: PushMessage) => this.taskMessagePushed(msg)));
    this.subscriptions.push(this.authService.identityChanged.subscribe((id: Identity) =>
    {
      this.identity = id;
      this.keepTags = !this.identity?.company?.configuration?.removeDocumentTagsBetweenVersions;
      this.onKeepTagsChange(this.keepTags);
    }));
    this.docChange();
    this.identity = this.authService.getCurrentIdentity();
  }

  ngOnInit()
  {
    this.canApprove = this.permissionsService.currentUserHasPermissionTo(UserAction.approveDocuments);
    this.canReclassify = this.permissionsService.currentUserHasPermissionTo(UserAction.reclassifyDocuments);
  }

  ngOnDestroy()
  {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private docChange()
  {
    if (this.mode === 'Edit')
    {
      if (this.currentDocument?.id !== this.currentDocMgr?.editDocument?.id)
      {
        this.keepTags = !this.identity.company?.configuration?.removeDocumentTagsBetweenVersions;
        this.onKeepTagsChange(this.keepTags);
      }
      this.currentDocument = this.currentDocMgr.editDocument;
    }
    else
    {
      this.currentDocument = this.currentDocMgr.importDocument;
    }

    this.checkDocFromTask();
    if (this.docFromTask)
    {
      this.subTaskMessagesCount = this.getSubTaskMessagesCount();
    }
    if (this.dialogRef)
    {
      this.dialogRef.close();
      this.dialogRef = undefined;
    }
  }

  private taskChange(task: MoonTask)
  {
    this.currentTask = task;
    this.checkDocFromTask();
    if (this.docFromTask)
    {
      this.subTaskMessagesCount = this.getSubTaskMessagesCount();
    }
  }

  private taskMessagePushed(msg: PushMessage)
  {
    if (!this.currentTask || !msg.taskNotification || this.currentTask.id !== msg.taskNotification.task.id ||
       !msg.taskNotification.subTaskMessage)
    {
      return;
    }
    const subTaskMessage = msg.taskNotification.subTaskMessage;
    const subTask = _.find(this.currentTask.subTasks, st => st.id === subTaskMessage.subTaskId);
    if (!subTask)
    {
      // should not happend
      return;
    }
    if (!_.any(subTask.subTaskMessages, stm => stm.id === subTaskMessage.id))
    {
      subTask.subTaskInfos.totalMessagesCount++;
    }
    this.subTaskMessagesCount = this.getSubTaskMessagesCount();
  }

  subTaskState(): 'ToDo' | 'InReview' | 'Done' | 'Closed'
  {
    if (!this.currentDocument || !this.currentDocument.id)
    {
      return undefined;
    }
    const subtask = _.find(this.currentTask.subTasks, st => st.document.id === this.currentDocument.id);
    if (subtask)
    {
      return subtask.status;
    }
    return undefined;
  }

  canSendToReview(): boolean
  {
    return this.subTaskState() === 'ToDo';
  }

  canSendToDesign(): boolean
  {
    const identity = this.authService.getCurrentIdentity();
    const isAdmin = identity && identity.role === CompanyRole.admin;
    const user = identity.user;
    const task = this.currentTask;
    const isCreatorOrOwner = (task.createdBy && task.createdBy.id === user.id) || (task.owner && task.owner.id === user.id);
    if (user && task && this.subTaskState() === 'InReview' && (user.isSuperUser || isAdmin || isCreatorOrOwner))
    {
      return true;
    }
    return false;
  }

  // TASKS
  private checkDocFromTask()
  {
    if (
      this.currentDocument &&
      this.currentTask &&
      _.some(this.currentTask.subTasks, subTask => subTask.document.id === this.currentDocument.id))
    {
      this.docFromTask = true;
    }
    else
    {
      this.docFromTask = false;
    }
  }

  async addDocToTask()
  {
    this.docFromTask = await this.showAddDocToTaskPopup(this.currentTask, this.currentDocument);
    // TODO UPDATE CURRENT TASK AND TODOLIST
  }

  private showAddDocToTaskPopup(task: MoonTask, document: MoonDeskDocument): Promise<boolean>
  {
    return new Promise<boolean>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(AddDocToTaskDialogComponent, {
        width: 'auto',
        minWidth: 'auto',
        data: {
          task: task,
          document: document
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => resolve(result));
    });
  }

  openTaskPreview(task: MoonTask)
  {
      let currentSubTask;
      if (this.currentDocument)
      {
        currentSubTask = _.find(task.subTasks, subTask => subTask.document.id === this.currentDocument.id);
      }
      const taskPreviewData: TaskDescriptionPopupData = {taskId: task.id, subTaskId: currentSubTask?.id};
      this.eventHubService.openTaskView.emit(taskPreviewData);
  }

  async changeSubTaskStatus(status: 'ToDo' | 'InReview')
  {
    this.saving = true;
    const currentSubTask = _.find(this.currentTask.subTasks, subTask => subTask.document.id === this.currentDocument.id);
    await this.workManager.changeSubTaskStatus(currentSubTask, status);
    this.saving = false;
  }

  async openVersionStatusDialog()
  {
    if (!this.canApprove)
    {
      return;
    }
    const version = this.currentDocument.editingVersion;
    await this.showDocumentVersionStatusPopup(version);
  }

  private showDocumentVersionStatusPopup(version: DocumentVersion): Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      this.dialogRef = this.dialog.open(DocumentStatusPopupComponent, {
        width: 'auto',
        minWidth: 'auto',
        data: {
          docVersion: version
        },
        disableClose: true
      });
      this.dialogRef.afterClosed().subscribe(result => resolve());
    });
  }

  async changeInfos()
  {
    await this.showClassSelectorPopup(this.currentDocument);
  }

  private showClassSelectorPopup(document: MoonDeskDocument): Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      this.dialogRef = this.dialog.open(ClassSelectorDialogComponent, {
        width: 'auto',
        minWidth: 'auto',
        data: {
          document: document
        },
        disableClose: true
      });
      this.dialogRef.afterClosed().subscribe(result => resolve());
    });
  }

  getTitleTooltip(): string
  {
    return this.currentDocument.workingCopy;
  }

  getDocName(): string
  {
    let name = '';
    if (this.currentDocument && this.currentDocument.classValues && this.mode === 'Edit')
    {
      name = _.map(this.currentDocument.classValues, cv => cv.name).join(' | ');
    }
    else
    {
      name = this.currentDocument ? this.fileService.filenameOf(this.currentDocument.workingCopy) : '==';
    }
    return name;
  }

  getVersionText()
  {
    const open = `${this.currentDocument.editingVersion.versionNumber}.${this.currentDocument.editingVersion.minorVersionNumber}`;
    const latest = `${this.currentDocument.latestMayorVersionNumber}.${this.currentDocument.latestMinorVersionNumber}`;
    if (open === latest)
    {
      return `version ${open} (latest)`;
    }
    else
    {
      return `version ${open} (latest ${latest})`;
    }
  }

  getVersionTooltip()
  {
    if (this.currentDocument.editingVersion.versionNumber === this.currentDocument.latestMayorVersionNumber
      && this.currentDocument.editingVersion.minorVersionNumber === this.currentDocument.latestMinorVersionNumber)
    {
      return `You're editing the latest version of this document`;
    }
    return `You're editing an older version of this document`;
  }

  getStatusTooltip()
  {
    switch (this.currentDocument.editingVersion.status)
    {
      case 'Draft':
      if (this.currentDocument.editingVersion.approvedRejectedBy)
      {
        return `Document is rejected by ${this.currentDocument.editingVersion.approvedRejectedBy.username}`;
      }
      else
      {
        return 'Document is a draft';
      }
      case 'Approved':
        if (this.currentDocument.editingVersion.approvedRejectedBy)
        {
          return `Document is approved by ${this.currentDocument.editingVersion.approvedRejectedBy.username}`;
        }
        else
        {
          return 'Document is approved';
        }
    }
  }

  getSubTaskMessagesCount(): number
  {
    let result = 0;
    if (!this.currentTask || !this.currentDocument)
    {
      return 0;
    }
    const subTask = _.find(this.currentTask.subTasks, st => st.documentId === this.currentDocument.id);
    if (subTask)
    {
      result = subTask.subTaskInfos.totalMessagesCount;
    }
    return result;
  }

  openSubTaskView()
  {
    if (!this.currentTask || !this.currentDocument)
    {
      return;
    }
    const subTask = this.currentDocIsFromTask();
    if (!subTask)
    {
      // Should not happend
      this.feedbackService.notifyError('Current document is not from the current task');
    }
    const taskPreviewData: TaskDescriptionPopupData = {taskId: this.currentTask.id, subTaskId: subTask.id};
    this.eventHubService.openTaskView.emit(taskPreviewData);
  }

  currentDocIsFromTask()
  {
    return _.find(this.currentTask.subTasks, st => st.documentId === this.currentDocument.id);
  }

  closeTask()
  {
    this.workManager.closeTask();
  }

  // showDocumentHeader()
  // {
  //   if (this.mode === 'Edit')
  //   {
  //     return this.currentDocument && this.currentDocument.id;
  //   }
  //   else if (this.mode === 'Import')
  //   {
  //     return this.currentDocument;
  //   }
  // }

  async checkDocRules()
  {
    this.busy = true;
    try
    {
      if (this.currentDocument.documentType)
      {
        const docItems = await this.illService.getVisibleItems(this.currentDocument.workingCopy, 0);
        const version =
          await this.saveDocumentService.createDocumentVersion(this.currentDocument, docItems, this.currentDocument.workingCopy);
        await this.ruleHelperService.runRulesAndShowResultsDialog(
          this.currentDocument, version, this.currentDocument.workingCopy, this.mode === 'Edit', true, false, this.currentTask);
        this.busy = false;
      }
      else
      {
        this.feedbackService.notifyMessage('Please select a document type');
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error checking rules', err);
    }
    this.busy = false;
  }

  async createRule()
  {
    this.busy = true;
    await this.openCreateRuleDialog();
    this.busy = false;
  }

  private openCreateRuleDialog()
  {
    return new Promise<void>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(RuleCreateComponent, {
        width: '80%',
        height : '80%',
        data: {
          document: this.currentDocument
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => resolve());
    });
  }

  isSuperUser()
  {
    return this.identity && this.identity.user && this.identity.user.isSuperUser;
  }

  copyMoonNumber()
  {
    if (!this.currentDocument)
    {
      return;
    }
    this.clipboard.copy(this.currentDocument.moonNumber);
    this.feedbackService.notifyMessage('MoonNumber copied to clipboard');
  }

  getTaskStatus(task: MoonTask)
  {
    let status = <any>this.taskService.getTasksStatus(task);
    if (status === 'In Work')
    {
      status = 'In Design';
    }
    return status;
  }

  onKeepTagsChange(value: boolean)
  {
    this.keepTagsChange.emit(value);
  }

}
