<div class="library-card list-view" *ngIf="viewMode === 'list'">
  <span class="resource-icon" *ngIf="libraryType === 'image'">
    <mat-icon class="notranslate">image</mat-icon>
  </span>
  <span class="resource-icon" *ngIf="libraryType === 'font'">
    <span class="char-icon">f</span>
  </span>
  <span class="resource-name" [matTooltip]="_libraryElement.document.name">
    <span 
    class="file-name" 
    >{{_libraryElement.document.name}}</span>
    <span class="file-size" *ngIf="_libraryElement.version?.storageUsage > 0">
      {{getFormattedVersionSize(_libraryElement.version?.storageUsage)}}
    </span>
  </span>
  <div class="actions">
    <ng-container *ngIf="!busy">
      <div class="action-element" *ngIf="libraryType === 'image' && !_libraryElement.document.markedAsDeleted">
        <span class="action-button" (click)="placeImage()">
          <mat-icon class="notranslate">add_photo_alternate_filled</mat-icon>
          <span class="text">Paste</span>
        </span>
      </div>
      <div class="action-element" *ngIf="!_libraryElement.document.markedAsDeleted">
        <span class="action-button" (click)="download()">
          <mat-icon class="notranslate">save_alt</mat-icon>
          <span class="text">Download</span>
        </span>
      </div>
      <div class="action-element">
        <span class="tag-button" [libTooltip]="tagsToolTip" (click)="openEditTags()">
          <mat-icon class="notranslate">label</mat-icon>
        </span>
      </div>
      <div class="action-element">
        <span class="menu-button" [matMenuTriggerFor]="appMenu">
          <mat-icon class="notranslate">more_horiz</mat-icon>
        </span>
        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="edit(_libraryElement)">Edit name</button>
          <button mat-menu-item *ngIf="!_libraryElement.document.markedAsDeleted" (click)="openEditTags()">Tag</button>
          <button mat-menu-item *ngIf="isAdminOrSuperUser() && !_libraryElement.document.markedAsDeleted" (click)="archive()">Archive</button>
          <button mat-menu-item *ngIf="isAdminOrSuperUser() && _libraryElement.document.markedAsDeleted" (click)="restore()">Restore</button>
          <button mat-menu-item *ngIf="!_libraryElement.document.markedAsDeleted" (click)="download()">Download</button>
          <button *ngIf="libraryType === 'image' && !_libraryElement.document.markedAsDeleted" mat-menu-item (click)="placeImage()">Paste</button>
          <button mat-menu-item (click)="searchLinkedParents(_libraryElement)">Linked Documents</button>
          <button mat-menu-item (click)="regeneratePreviews()">Regenerate Preview</button>
        </mat-menu>
      </div>
    </ng-container>
    <ng-container *ngIf="busy">
      <mat-progress-spinner
        class="spinner"
        diameter="20"
        color="accent"
        [mode]="_libraryElement.progress?.percentage > 0 && _libraryElement.progress?.percentage < 100 ? 'determinate' : 'indeterminate'"
        [value]="_libraryElement.progress?.percentage">
      </mat-progress-spinner>
    </ng-container>
  </div>
</div>

<div class="library-card grid-view" *ngIf="viewMode === 'grid'">
  <div class="preview">
    <img *ngIf="libraryType === 'image'" [src]="_libraryElement.document.previewImageUrl">
    <span class="font-preview" *ngIf="libraryType === 'font'">Aa</span>
  </div>
  <div class="card-footer">
    <div class="footer-element">
      <span class="resource-name" [matTooltip]="_libraryElement.document.name">
        <span     
        class="file-name">{{_libraryElement.document.name}}</span>
        <span class="file-size" *ngIf="_libraryElement.document.latestVersion?.storageUsage > 0">
          {{getFormattedVersionSize(_libraryElement.document.latestVersion?.storageUsage)}}
        </span>
      </span>
      <span class="menu-button" [matMenuTriggerFor]="appMenu" *ngIf="!busy">
        <mat-icon class="notranslate">more_horiz</mat-icon>
      </span>
      <span class="menu-button" *ngIf="busy">
        <mat-progress-spinner
          class="spinner"
          diameter="18"
          color="accent"
          [mode]="_libraryElement.progress?.percentage > 0 && _libraryElement.progress?.percentage < 100 ? 'determinate' : 'indeterminate'"
          [value]="_libraryElement.progress?.percentage">
        </mat-progress-spinner>
      </span>
      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="edit(_libraryElement)">Edit name</button>
        <button mat-menu-item *ngIf="!_libraryElement.document.markedAsDeleted" (click)="openEditTags()">Tag</button>
        <button mat-menu-item *ngIf="isAdminOrSuperUser() && !_libraryElement.document.markedAsDeleted" (click)="archive()">Archive</button>
        <button mat-menu-item *ngIf="isAdminOrSuperUser() && _libraryElement.document.markedAsDeleted" (click)="restore()">Restore</button>
        <button mat-menu-item *ngIf="!_libraryElement.document.markedAsDeleted" (click)="download()">Download</button>
        <button *ngIf="libraryType === 'image' && !_libraryElement.document.markedAsDeleted" mat-menu-item (click)="placeImage()">Paste</button>
        <button mat-menu-item (click)="searchLinkedParents(_libraryElement)">Linked Documents</button>
        <button mat-menu-item (click)="regeneratePreviews()">Regenerate Preview</button>
      </mat-menu>
    </div>
    <div class="footer-element">
      <div class="actions">
        <div class="action-element">
          <span class="action-button" [libTooltip]="tagsToolTip" (click)="openEditTags()">
            <mat-icon class="notranslate">label</mat-icon>
          </span>
        </div>
      </div>
      <div class="actions">
        <div class="action-element with-margin" *ngIf="libraryType === 'image' && !_libraryElement.document.markedAsDeleted">
          <span class="action-button" (click)="placeImage()" [matTooltip]="'Paste'">
            <mat-icon class="notranslate">add_photo_alternate_filled</mat-icon>
          </span>
        </div>
        <div class="action-element" *ngIf="!_libraryElement.document.markedAsDeleted">
          <span class="action-button" (click)="download()" [matTooltip]="'Download'">
            <mat-icon class="notranslate">save_alt</mat-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Templates -->
<ng-template #tagsToolTip>
  <div class="tags-tooltip">
      <h4 class="label" translate>Tags</h4>
      <h5 class="no-element" *ngIf="!_libraryElement.version.documentTags || _libraryElement.version.documentTags.length === 0">
        No tags
      </h5>
      <h5 class="element" *ngFor="let tag of _libraryElement.version.documentTags">
        #{{tag.value}}
      </h5>
  </div>
</ng-template>
