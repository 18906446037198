import { Injectable } from '@angular/core';
import { CompanyRole, UserAction, UserAssignment } from '../_models/user/User';
import { AuthService } from './auth.service';
import * as _ from 'underscore';


@Injectable({
  providedIn: 'root'
})
export class PermissionsService
{

  constructor(private authService: AuthService)
  { }

  isAssignmentLimited(ua: UserAssignment): boolean
  {
    const user = ua.user;
    if (user && user.isSuperUser)
    {
      return false;
    }
    const isLimited =
        ua.forcedSelection &&
            (ua.forcedSelection.forcedClassSelections?.length > 0 ||
            ua.forcedSelection.forcedDocTypeIdsSelections?.length > 0 ||
            ua.forcedSelection.forcedVersionStates?.length > 0);
    return isLimited;
  }

  currentUserHasPermissionTo(action: UserAction): boolean
  {
    const identity = this.authService.getCurrentIdentity();
    if (!identity?.user)
    {
      return false;
    }
    const user = identity.user;

    if (user.isSuperUser || identity.role >= CompanyRole.admin)
    {
      return true;
    }
    else if (identity.role === CompanyRole.stakeholder)
    {
      return false;
    }

    const currentWorkspaceAssignment = _.find(identity.assignments, ua => ua.companyId === identity.company?.id);
    if (!currentWorkspaceAssignment)
    {
      return false;
    }

    return this.assignmentHasPermissionTo(currentWorkspaceAssignment, action);
  }

  assignmentHasAllPermissions(ua: UserAssignment): boolean
  {
    for (const action in UserAction)
    {
      const hasPermission = this.assignmentHasPermissionTo(ua, UserAction[action]);
      if (!hasPermission)
      {
        return false;
      }
    }
    return true;
  }

  // It must be equal to Backend.Data\Model\Users\UserAssignment.HasPermissionTo()
  assignmentHasPermissionTo(ua: UserAssignment, action: UserAction): boolean
  {
    // Add all configurable permissions here (search for this string to see all the places that should be modified)
    const assignmentPermissions = ua.assignmentPermissions;

    if (ua.role >= CompanyRole.admin)
    {
      return true;
    }
    if (ua.role === CompanyRole.stakeholder)
    {
      return false;
    }

    switch (action)
    {
    case UserAction.adminClassValues:
      if (ua.role >= CompanyRole.user && assignmentPermissions &&
            (assignmentPermissions.adminClassValues &&
             assignmentPermissions.adminClassValues === 'Allow'))
      {
        return true;
      }
      return false;

    case UserAction.approveDocuments:
      if (ua.role >= CompanyRole.user && assignmentPermissions &&
            (assignmentPermissions.approveDocuments &&
              assignmentPermissions.approveDocuments === 'Allow'))
      {
        return true;
      }
      return false;

    case UserAction.preapproveDocuments:
      if (ua.role >= CompanyRole.user && assignmentPermissions &&
            (assignmentPermissions.preapproveDocuments &&
              assignmentPermissions.preapproveDocuments === 'Allow'))
      {
        return true;
      }
      return false;

    case UserAction.deleteDocuments:
      if (ua.role >= CompanyRole.user && assignmentPermissions &&
            (assignmentPermissions.deleteDocuments &&
              assignmentPermissions.deleteDocuments === 'Allow'))
      {
        return true;
      }
      return false;

    case UserAction.uploadDocumentsFromWeb:
      if (ua.role >= CompanyRole.user && assignmentPermissions &&
            (assignmentPermissions.uploadDocumentsFromWeb &&
              assignmentPermissions.uploadDocumentsFromWeb === 'Allow'))
      {
        return true;
      }
      return false;

    case UserAction.uploadDocumentsFromExt:
      if (ua.role >= CompanyRole.user && assignmentPermissions &&
            (assignmentPermissions.uploadDocumentsFromExt &&
              assignmentPermissions.uploadDocumentsFromExt === 'Allow'))
      {
        return true;
      }
      return false;

    case UserAction.adminRules:
      if (ua.role >= CompanyRole.user && assignmentPermissions &&
            (assignmentPermissions.adminRules &&
              assignmentPermissions.adminRules === 'Allow'))
      {
        return true;
      }
      return false;

    case UserAction.reclassifyDocuments:
      if (ua.role >= CompanyRole.user && assignmentPermissions &&
            (assignmentPermissions.reclassifyDocuments &&
              assignmentPermissions.reclassifyDocuments === 'Allow'))
      {
        return true;
      }
      return false;

    case UserAction.adminProducts:
      if (ua.role >= CompanyRole.user && assignmentPermissions &&
						(assignmentPermissions.adminProducts &&
							assignmentPermissions.adminProducts === 'Allow'))
      {
        return true;
      }
      return false;

    case UserAction.viewAllTasks:
      if (ua.role < CompanyRole.user ||
						(assignmentPermissions &&
            assignmentPermissions.viewAllTasks &&
						assignmentPermissions.viewAllTasks === 'Deny'))
      {
        return false;
      }
      return true;

    case UserAction.createTasks:
      if (ua.role < CompanyRole.user ||
                    (assignmentPermissions &&
                    assignmentPermissions.createTasks &&
                    assignmentPermissions.createTasks === 'Deny'))
      {
        return false;
      }
      return true;

    case UserAction.updateTasksIfAssigned:
      if (ua.role >= CompanyRole.user && assignmentPermissions &&
                    (assignmentPermissions.updateTasksIfAssigned &&
                        assignmentPermissions.updateTasksIfAssigned === 'Allow'))
      {
        return true;
      }
      return false;

    default:
      return false;
    }
  }

}
