import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private authService: AuthService) { }

  async getFileChecksum(file: File): Promise<string>
  {
    const content = await this.toRaw(file);
    let abString = this.arrayBufferToWordArray(content);
    const checksum = crypto.SHA1(abString).toString(crypto.enc.Base64);

    return checksum;
  }

  toRaw(file: File): Promise<ArrayBuffer>
  {
    return new Promise((resolve, reject) =>
    {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = () =>
      {
        // resolve(reader.result.toString());
        resolve(<ArrayBuffer>reader.result);
      };
      reader.onerror = error => reject(error);
    });
  }

  fileToBase64(file: File): Promise<string>
  {
    return new Promise((resolve, reject) =>
    {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () =>
      {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        if ((encoded.length % 4) > 0)
        {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
   });
  }

  async readFileAsync(file: File): Promise<string | ArrayBuffer>
  {
    return new Promise<string | ArrayBuffer>((resolve, reject) =>
    {
      const fileReader = new FileReader();
      
      fileReader.onload = (e) =>
      {
        const result = fileReader.result;
        resolve(result);
      };
      
      fileReader.onerror = (e) =>
      {
        reject(e);
      };
      
      fileReader.readAsText(file);
    });
  }

  private arrayBufferToWordArray(ab: ArrayBuffer) {
    var i8a = new Uint8Array(ab);
    var a = [];
    for (var i = 0; i < i8a.length; i += 4) {
      a.push(i8a[i] << 24 | i8a[i + 1] << 16 | i8a[i + 2] << 8 | i8a[i + 3]);
    }
    return crypto.lib.WordArray.create(a, i8a.length);
  }
}
